import { Component, Inject, Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

interface IButtonDefinition {
  text: string;
  color?: 'primary' | 'alert' | 'warn';
  dialogResult: any;
  matButtonType: 'stroked' | 'flat';
  isFormSubmitButton?: boolean;
}

export interface IDialogParams {
  message: string;
  title?: string;
  input?: {
    formControl?: FormControl;
    tooltip?: string;
    placeholder?: string;
  };
  buttons?: IButtonDefinition[];
}


@Component({
  selector: 'app-configurable-dialog',
  templateUrl: './configurable-dialog.component.html',
  styleUrls: ['./configurable-dialog.component.scss']
})
export class ConfigurableDialogComponent {
  _message: string[];
  _title = 'Continue?';
  _buttons: IButtonDefinition[] = [{
    text: 'Reject',
    dialogResult: false,
    matButtonType: 'stroked'
  }, {
    text: 'Confirm',
    color: 'primary',
    dialogResult: true,
    matButtonType: 'flat'
  }];
  _formControl?: FormControl;
  _tooltip?: string;
  _placeholder?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: IDialogParams,
      public _dialog: MatDialogRef<ConfigurableDialogComponent, true | null | undefined>
  ) {
    this._message = data.message.split('\n').filter(line => line.length > 0);
    if (data.input?.formControl) {
      this._formControl = data.input.formControl;
    }

    if (data.input?.tooltip) {
      this._tooltip = data.input.tooltip;
    }

    if (data.input?.placeholder) {
      this._placeholder = data.input.placeholder;
    }

    if (data.title) {
      this._title = data.title;
    }

    if (data.buttons !== undefined) {
      this._buttons = data.buttons;
    }

    this.getInputErrorMessage = this.getInputErrorMessage.bind(this);
  }

  getInputErrorMessage(): string {
    const control = this._formControl;
    if (control !== undefined) {
      if (control.hasError('required')) {
        return 'Name is required.';
      }

      if (control.hasError('minLength')) {
        return 'The minimum length is one character.';
      }

      if (control.hasError('nameTaken')) {
        return 'This name is taken already. Please provide a different name.';
      }
    }
    return 'Invalid name';
  }

  close(button: IButtonDefinition) {
    if (button.isFormSubmitButton) {
      return this._dialog.close(this._formControl?.value);
    } else {
      this._dialog.close(button.dialogResult);
    }
  }
}

/**
 * A sub-set of MatDialogConfig
 *
 * @see MatDialogConfig for more details
 */
export interface IDialogExtras {
  maxWidth?: number | string;
}

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(private dialog: MatDialog) {

  }

  open(params: IDialogParams, extras?: IDialogExtras) {
    return this.dialog.open<
      ConfigurableDialogComponent,
      IDialogParams,
    true | null | undefined | any
    >(
      ConfigurableDialogComponent,
      {
        ...extras,
        data: params,
      }
    );
  }
}
