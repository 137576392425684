<ng-container *ngIf="askForRefresh === false">
  <div *ngIf="backendTimeout === false" class="flex flex-col items-center">
    <mat-spinner></mat-spinner>
    <br/>
    Waiting for response from DOTS Server...
  </div>
  <div *ngIf="backendTimeout === true" class="flex flex-col">
    No response from DOTS Server, please contact your administrator.
    <br/>
    <div class="flex">
      <div class="grow"></div>
      <button mat-flat-button color="primary" (click)="retry()">Retry</button>
    </div>
  </div>
</ng-container>
<div *ngIf="askForRefresh === true" class="flex flex-col">
  There was a connection issue with the server. Some parts of the software may not work anymore. Please save any changes, and reload the page.
  <br/>
  <div class="flex gap-2">
    <div class="grow"></div>
    <button mat-stroked-button color="accent" (click)="reload()">Reload now</button>
    <button mat-flat-button color="primary" (click)="close()">Close</button>
  </div>
</div>
