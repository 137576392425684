import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { DateTime } from 'luxon';
import { lastValueFrom, Observable, ReplaySubject } from 'rxjs';
import { IUser } from 'src/app/model/user-management/user';
import { DefaultConfig } from 'src/assets/default.config';
import { ICreateUserDTO, IUserUpdateChanges, UserService } from '../user.service';

interface IUserDTO extends Omit<IUser, 'createdAt'> {
  createdAt: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserServiceImpl implements UserService {
  private savedPinsMap$ = new ReplaySubject<Map<string, number>>(1);
  private savedPinsMap = new Map<string, number>();
  constructor(private http: HttpClient, private keycloak: KeycloakService) {
    void this.createSavedPinsMap();
  }

  getSavedPins(): Observable<Map<string, number>> {
    return this.savedPinsMap$;
  }

  getPinnedThingies(): Map<string, number> {
    return this.savedPinsMap;
  }

  pinThingies(relatives: string[]): void {
    relatives.forEach(relative => {
      this.savedPinsMap.set(relative, Date.now());
    });
    this.savedPinsMap$.next(this.savedPinsMap);
  }

  unpinThingies(relatives: string[]): void {
    relatives.forEach(relative => {
      this.savedPinsMap.delete(relative);
    });
    this.savedPinsMap$.next(this.savedPinsMap);
  }

  async createSavedPinsMap() {
    const user = this.getCurrentUser();
    this.savedPinsMap = new Map(Object.entries((await user).pinnedCards));
    this.savedPinsMap$.next(this.savedPinsMap);
  }

  getCurrentUser(): Promise<IUser> {
    const currentUserId = this.keycloak.getKeycloakInstance().subject;
    if (currentUserId === undefined) {
      throw new Error('userId is undefined');
    }
    return this.getUserById({ userId: currentUserId });
  }

  async createUser(user: ICreateUserDTO): Promise<IUser> {
    const dto = (await lastValueFrom(this.http.post<IUserDTO>(
      DefaultConfig.userManagement.user,
      user
    )))!;

    return this.convertDtoToUser(dto);
  }

  /**
   * Update user
   *
   *
   */
  async updateUser(opts: {
    userId: string;
    changes: IUserUpdateChanges;
  }): Promise<void> {
    const uri = `${DefaultConfig.userManagement.user}/${encodeURIComponent(opts.userId)}`;

    (await lastValueFrom(this.http.put(uri, opts.changes)))!;
  }

  async lockUser(opts: { userId: string }): Promise<void> {
    const uri = DefaultConfig.userManagement.lock
      .replace('{id}', encodeURIComponent(opts.userId));

    (await lastValueFrom(this.http.post(uri, undefined)))!;
  }

  async unlockUser(opts: { userId: string }): Promise<void> {
    const uri = DefaultConfig.userManagement.unlock
      .replace('{id}', encodeURIComponent(opts.userId));

    (await lastValueFrom(this.http.post(uri, undefined)))!;
  }

  async addPinnedCard(thingieIds: string): Promise<void> {
    const uri = DefaultConfig.userManagement.addPinnedCard
      .replace('{thingieids}', encodeURIComponent(thingieIds));

    (await lastValueFrom(this.http.put(uri, undefined)))!;
  }

  async removePinnedCard(thingieIds: string): Promise<void> {
    const uri = DefaultConfig.userManagement.removePinnedCard
      .replace('{thingieids}', encodeURIComponent(thingieIds));

    (await lastValueFrom(this.http.delete(uri, undefined)))!;
  }

  async getUserList(): Promise<IUser[]> {
    const uri = DefaultConfig.userManagement.user;

    const userList = (await lastValueFrom(this.http.get<IUserDTO[]>(uri)))!;

    return this.convertDtoListToUserList(userList);
  }

  async getUserListByIds(ids: string): Promise<IUser[]> {
    const uri = DefaultConfig.userManagement.usersByIds.replace('{userIDs}', encodeURIComponent(ids));

    const userList = (await lastValueFrom(this.http.get<IUserDTO[]>(uri)))!;

    return this.convertDtoListToUserList(userList);
  }

  async getUserById(opts: { userId: string }): Promise<IUser> {
    const uri = `${DefaultConfig.userManagement.user}/${encodeURIComponent(opts.userId)}`;

    const user = (await lastValueFrom(this.http.get<IUserDTO>(uri)))!;

    return this.convertDtoToUser(user);
  }

  async setUserPassword(opts: { userId: string; password: string }): Promise<void> {
    const uri = DefaultConfig
      .userManagement
      .changePassword
      .replace('{id}', encodeURIComponent(opts.userId));
    const pwdDocument = { password: opts.password };

    (await lastValueFrom(this.http.post(uri, pwdDocument)))!;
  }

  async forceLogout(opts: { userId: string }): Promise<void> {
    const uri = DefaultConfig.userManagement.forceLogout
      .replace('{id}', encodeURIComponent(opts.userId));

    (await lastValueFrom(this.http.post(uri, undefined)))!;
  }

  private convertDtoToUser(dto: IUserDTO): IUser {
    return {
      _id: dto._id,
      createdAt: DateTime.fromISO(dto.createdAt),
      creator: dto.creator,
      displayName: dto.displayName,
      admin: dto.admin,
      projects: dto.projects,
      pinnedCards: dto.pinnedCards,
      teams: dto.teams,
      enabled: dto.enabled,
      username: dto.username,
      email: dto.email
    };
  }

  private convertDtoListToUserList(dtoList: IUserDTO[]): IUser[] {
    return dtoList.map(dto => this.convertDtoToUser(dto));
  }
}
