import { AnnotationSeries } from '../model/annotation-series';
import { IDataSeries } from '../model/data-series';
import { DeviceSeries } from '../model/device/device-series';
import { IGraphConfig } from '../model/graph-configs/graph-config';
import { ITab } from '../model/tab';
import { ThingieSeries } from '../model/thingie-series';
import { IAnnotation } from '../model/thingie/annotation';
import { ITask } from '../model/thingie/task';
import { IProcess, IThingie } from '../model/thingie/thingie';
import { IValuesConfiguration } from '../model/values-config';
import { generateNewTabUUID } from './uuid-generator';

export function createDefaultDataSeries(): Omit<Omit<IDataSeries, 'identification'>, 'valueConfig'> {
  return {
    alias: '',
    observed: false,
    description: '',
    dataItemDescription: {}
  };
}

export function createDefaultThingieSeries(): ThingieSeries {
  return {
    ...createDefaultDataSeries(),
    identification: {
      type: 'thingieSeries',
      dimensionName: '',
      taskIndex: -1,
      output: '',
      processIndex: -1,
      thingieId: '',
    },
    valueConfig: {
      type: 'scatterValues',
      mode: 'LINES',
      positioning: {
        rowIndex: 0,
        yAxisSide: 'LEFT'
      },
      unit: '',
      color: '',
      displayRangeYAxis: {
        enabled: false,
        min: null,
        max: null
      },
      rangeToDisplay: {
      },
      timeAxisOffset: 0,
      yAxisOffset: 0,
      forecasting: {
        enabled: false,
        fittingModel: 'POLYNOMIAL_ORDER_1',
        periodLength: 0.5
      },
      denoising: {
        enabled: false,
        intensity: 'LOW',
        interval: [0, 0]
      },
      calibration: {
        enabled: false,
      }
    }
  };
}

export function createDefaultGraphConfig(): IGraphConfig {
  return {
    uuid: generateNewTabUUID(),
    label: '',
    timeReference: 'RELATIVE',
    internalSeriesList: [],
    autoOffsets: {
      timeAxis: false,
      yAxis: false
    }
  };
}

export function createDefaultTab(): ITab {
  return {
    backendId: null,
    graphConfig: createDefaultGraphConfig(),
    outputData: [
    ],
    legend: [],
    _isDashboardCard: false
  };
}

export function createEmptyThingie(): IThingie {
  return {
    name: '',
    baseName: '',
    type: 'real',
    tags: [],
    project: '',
    relations: [],
    processes: [],
    properties: [],
    lastEdited: new Date().toISOString(),
    lastEditor: '',
    created: new Date().toISOString(),
    creator: '',
    defaultGraphConfigs: [],
    archived: false
  };
}

export function createDefaultThingie(): IThingie {
  return {
    ...createEmptyThingie(),
    processes: [{
      ...createEmptyThingieProcess(),
      name: 'Process 1'
    }]
  };
}

export function createEmptyThingieProcess(): IProcess {
  return {
    annotations: [],
    removed: false,
    name: '',
    relations: [],
    tasks: []
  };
}

export function createEmptyThingieTask(): ITask {
  return {
    duplicated: false,
    dataProcessors: [],
    inputs: {},
    name: '',
    outputs: {},
    pausedTimeRanges: []
  };
}

export function createDefaultDeviceDataSeries(): DeviceSeries {
  return {
    ...createDefaultThingieSeries(),
    identification: {
      type: 'deviceSeries',
      deviceAddress: '',
      packetType: '',
      property: '',
    }
  };
}
export function createDefaultArrayValuesConfiguration(): IValuesConfiguration {
  return {
    type: 'arrayValues',
    mode3D: 'SURFACE',
    mode: 'LINES',
    positioning: {
      rowIndex: 0,
      yAxisSide: 'LEFT'
    },
    unit: '',
    color: '',
    rangeToDisplay: {
    },
    timeAxisOffset: 0,
    yAxisOffset: 0,
    forecasting: {
      enabled: false,
      fittingModel: 'POLYNOMIAL_ORDER_1',
      periodLength: 0.5
    },
    denoising: {
      enabled: false,
      intensity: 'LOW',
      interval: [0, 0]
    },
    calibration: {
      enabled: false,
    },
    displayRangeYAxis: {
      enabled: false,
      min: null,
      max: null
    },
  };
}

export function createDefaultAnnotationDataSeries(): AnnotationSeries {
  return {
    ...createDefaultThingieSeries(),
    identification: {
      type: 'annotationSeries',
      thingieId: '',
      systemAnnotation: false,
    },
    valueConfig: {
      type: 'annotationValues',
      color: '',
      rangeToDisplay: {
      },
      timeAxisOffset: 0,
      positioning: {
        rowIndex: 0,
        yAxisSide: 'LEFT'
      },
    }
  };
}

export function createEmptyAnnotation(): IAnnotation {
  return {
    creator: 'Kevin',
    deleted: false,
    hidden: false,
    files: [],
    label: '',
    timestamp: new Date().toISOString(),
  };
}
