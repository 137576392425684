<div class="flex gap-3 items-center mb-5">
  <!-- IP Address -->
  <div>
    <label class="block mb-0.5">IPv4 Address</label>
    <app-ipv4-address (valueChange)="_ipAddress = $event; _checkAndEmit()" />
  </div>

  <!-- Port -->
  <div>
    <label class="block">Port</label>
    <mat-form-field class="[&>.mat-form-field-wrapper]:!pb-0">
      <input
        matInput
        type="number"
        min="0"
        max="65535"
        step="1"
        required
        [(ngModel)]="_port"
        (ngModelChange)="_checkAndEmit()" />
    </mat-form-field>
  </div>
</div>
