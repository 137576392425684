<h2 mat-dialog-title>Add device</h2>

<mat-dialog-content class="!overflow-x-hidden relative">

  <div class="flex gap-2 items-center mb-6">
    <ion-icon name="alert-circle-outline" />
      USB devices are auto-detected and don't need to be added manually.
  </div>

  <div class="mb-4">
    <mat-form-field class="w-full">
      <mat-label>Device Type</mat-label>
      <mat-select
        (selectionChange)="_onSelectionChange($event.value)"
        placeholder="Select a device to add"
      >
        <mat-option
          *ngFor="let entry of _deviceTypesAvailable$ | async"
          [value]="entry.type"
        >
          {{ entry.text }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <ng-template #queryComponentHost />


  <div
    *ngIf="_processing$ | async"
    class="
      absolute
      top-0 left-0
      z-10
      w-full h-full
      backdrop-blur-sm
      bg-gradient-to-br from-[#7b7b7b80] to-[#464646b3]
    "
  >
    <div class="
      absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
      flex flex-col gap-2 items-center
    ">
      <mat-spinner />
      <span class="text-textBright">Creating device...</span>
    </div>
  </div>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-stroked-button [mat-dialog-close]="undefined">Cancel</button>

  <button
    mat-flat-button
    color="primary"
    cdkFocusInitial
    (click)="_onAddDevice()"
    [disabled]="(_canCreateDevice$ | async) === false || (_processing$ | async) === true"
  >
    Save
  </button>
</mat-dialog-actions>
