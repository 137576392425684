<div *ngIf="showBlockedPopout; else normalPopout" class="blocked-by-container">
  <div class="rowContainer">
    <h3>Currently used in</h3>
    <div class="title-close-container-flex">
      <div mat-dialog-close></div>
    </div>
  </div>
  <div *ngFor="let key of deviceObj?.blockedBy">
    <p
      (click)="showThingieDetail(key.thingie)">
      <a role="link">{{getThingieName(key.thingie)}}</a>
      <ion-icon class="open-icon" name="open-outline"></ion-icon>
    </p>
  </div>
</div>

<ng-template #normalPopout>
  <div class="device-detail-container">
    <div *ngIf="deviceObj" class="rowTitle">
      <ion-item class="deviceTitle" lines="none">
        <div class="statusButton">
          <img *ngIf="deviceObj?.info?.InternalState?.values?.connectionStatus === 'CONNECTED' && 
            deviceObj?.info?.InternalState?.values?.ready === true; else isWorking"
            class="middleAlign"
            [src]="'assets.icons.byConnectionState.Connected' | translate"
            matTooltip="Connected" />

          <ng-template #isWorking>
            <img *ngIf="deviceObj?.info?.InternalState?.values?.connectionStatus === 'CONNECTED' && 
              deviceObj?.info?.InternalState?.values?.ready === false; else isDisconnected" 
              class="workingDeviceIcon middleAlign"
              [src]="'assets.icons.byConnectionState.Working' | translate"
              matTooltip="Working" />
          </ng-template>

          <ng-template #isDisconnected>
            <img *ngIf="deviceObj?.info?.InternalState?.values?.connectionStatus === 'DISCONNECTED'; else isErrored" 
              class="inline-block align-middle"
              [src]="'assets.icons.byConnectionState.NotConnected' | translate"
              matTooltip="Not connected" />
          </ng-template>

          <ng-template #isErrored>
            <img class="inline-block align-middle"
              [src]="'assets.icons.byConnectionState.Error' | translate"
              matTooltip="Connection Error" />
          </ng-template>
        </div>

        <ng-container *ngIf="isEditingName; else notEditingName">
          &nbsp;&nbsp;
          <form class="inLineElements">
            <input
                [formControl]="newNameFormControl"
                matInput
                type="text"
                id="newName"
                placeholder="{{deviceObj?.name}}"
                required />
            <button
              class="action-button "
              type="submit"
              (click)="saveNameEdit(newNameFormControl.value)"
              mat-flat-button
              color="primary"
            >
              <ion-icon  name="save-outline" /><span >Save</span>
            </button>
          </form>
        </ng-container>
        <ng-template #notEditingName>
          <span class="noEditElement label">
            {{deviceObj?.name}} 
            <ion-button class="noEditElement" fill="clear" color="dark" size="small" (click)="toggleNameEdit()">
              <ion-icon (click)="toggleNameEdit()" name="pencil-outline"></ion-icon>
            </ion-button>
          </span>
        </ng-template> 

      </ion-item>

      <div class="space"></div>

      <div class="save-button-wrapper rowContainer" >
        <ion-button fill="clear" color="dark" size="small" 
          [mat-menu-trigger-for]="extendedConfig"
        >
          <ion-icon name="settings-outline"></ion-icon>
        </ion-button>
        <mat-menu
          #extendedConfig
          class="menu-6c55eac2-2022-4979-a92d-a3127535e0cc"
          xPosition="before"
        >
          <button
            mat-menu-item
            class="actionSize"
            (click)="onResetStatus()"
          >
            <span >
                Reset
            </span>         
          </button>

          <mat-divider />

          <button
            mat-menu-item
            *ngIf="isAdmin"
            #matOption
            class="actionSize"
            (click)="toggleDevicesArchived()"
          >
            <span>
              {{ isDeviceArchived === true ? 'Restore' : 'Archive' }} 
            </span>
          </button>
        </mat-menu>
      </div>
      <div class="title-close-container-flex">
        <div mat-dialog-close></div>
      </div>
    </div>

    <mat-divider />

    <div class="rowContainer">

      <!-- left column -->
      <div class="flex flex-col justify-start gap-3 pr-[15px] grow-[1]">
        <div>
          <ng-container *ngIf="deviceObj?.blockedBy">
            <small class="label">Currently used in</small>
            <div *ngIf="deviceObj?.blockedBy?.length === 0; else oneThingie">
              <p class="text">-</p>
            </div>
            <ng-template #oneThingie>
              <div *ngIf="deviceObj?.blockedBy?.length === 1; else multipleThingies">
                <div *ngFor="let key of deviceObj?.blockedBy">
                  <p (click)="showThingieDetail(key.thingie)">
                    <a role="link" class="hover:cursor-pointer">{{getThingieName(key.thingie)}} <ion-icon class="open-icon" name="open-outline"></ion-icon></a>
                  </p>
                </div>
              </div>
            </ng-template>
            <ng-template #multipleThingies>
              <div>
                <a class="clickable text" (click)="showBlockedByPopout()">
                  {{deviceObj?.blockedBy?.length}}&nbsp;objects
                </a>
              </div>
            </ng-template>
          </ng-container>
        </div>

        <div>
          <small class="label">Device Type</small>
            <p class="text">{{ 'deviceLabels.'+deviceObj?.deviceInputTypes?.[0] | translate:{type: deviceObj?.deviceInputTypes?.[0]} }}</p>
        </div>


        <!-- Power state -->
        <div *ngIf="deviceObj?.deviceInputTypes?.[0] === 'AquilaLisDrive'">
          <small class="label">Power state</small>
          <p *ngIf="this.deviceObj?.info?.InternalState?.values?.connectionStatus !== 'CONNECTED'; else isPowerState" class="text">
            -
          </p>
          <ng-template #isPowerState>
            <p *ngIf="deviceObj?.info?.StatusAnswer?.values?.batteryStateIsCharging">
              <ion-icon
              class="battery-charging-icon"
              name="battery-charging-outline"></ion-icon>
              <ion-label class="text">Charging</ion-label>
            </p>
            <div *ngIf="!deviceObj?.info?.StatusAnswer?.values?.batteryStateIsCharging">
              <p *ngIf="getBatteryPower() === 'Full'; else checkHalf">
                <ion-icon
                class="battery-icon"
                name="battery-full-outline"></ion-icon>
                <ion-label class="text">{{deviceObj?.info?.StatusAnswer?.values?.batteryPercentage}}%</ion-label>
              </p>
              <ng-template #checkHalf>
                <p *ngIf="getBatteryPower() === 'Half'; else checkLow">
                  <ion-icon
                  class="battery-half-icon"
                  name="battery-half-outline"></ion-icon>
                  <ion-label class="text">{{deviceObj?.info?.StatusAnswer?.values?.batteryPercentage}}%</ion-label>
                </p>
              </ng-template>
              <ng-template #checkLow>
                <p *ngIf="getBatteryPower() === 'Low'">
                  <ion-icon
                  class="battery-dead-icon"
                  name="battery-dead-outline"></ion-icon>
                  <ion-label class="text">{{deviceObj?.info?.StatusAnswer?.values?.batteryPercentage}}%</ion-label>
                </p>
              </ng-template>
            </div>
          </ng-template>
        </div>

        <div>
          <small class="label">Connection state</small>
            <p class="text">{{connectionState}}</p>
        </div>


        <!-- Signal Strength -->
        <div *ngIf="deviceObj?.deviceInputTypes?.[0] === 'AquilaLisDrive'">
          <small class="label">Connection signal strength</small>
            <p *ngIf="getSignalStrengthQuality() === 'Excellent'; else checkGood" class="text">
              <img class="inline-block align-middle connectionImage"
              [src]="'assets.icons.bySignalStrength.Excellent' | translate"/>
              {{this.deviceObj?.info?.StatusAnswer?.values?.signalStrength}}
            </p>
            <ng-template #checkGood>
              <p *ngIf="getSignalStrengthQuality() === 'Good'; else checkOk" class="text">
                <img class="inline-block align-middle connectionImage"
                [src]="'assets.icons.bySignalStrength.Great' | translate"/>
                {{this.deviceObj?.info?.StatusAnswer?.values?.signalStrength}}
              </p>
            </ng-template>
            <ng-template #checkOk>
              <p *ngIf="getSignalStrengthQuality() === 'Ok'; else checkBad" class="text">
                <img class="inline-block align-middle connectionImage"
                [src]="'assets.icons.bySignalStrength.Good' | translate"/>
                {{this.deviceObj?.info?.StatusAnswer?.values?.signalStrength}}
              </p>
            </ng-template>
            <ng-template #checkBad>
              <p *ngIf="getSignalStrengthQuality() === 'Bad'; else noSignal" class="text">
                <img class="inline-block align-middle connectionImage"
                [src]="'assets.icons.bySignalStrength.Bad' | translate"/>
                {{this.deviceObj?.info?.StatusAnswer?.values?.signalStrength}}
              </p>
            </ng-template>
            <ng-template #noSignal>
              <p class="text">
                <img class="inline-block align-middle connectionImage"
                [src]="'assets.icons.bySignalStrength.Poor' | translate"/>
                -
              </p>
            </ng-template>
        </div>

        <!-- Wireless Channel Number -->
        <div *ngIf="deviceObj?.deviceInputTypes?.[0] === 'AquilaLisCoordinator'">
          <small class="label">Wireless channel number</small>
          <p class="text">{{deviceObj!.configuration['2'].values?.channel}}</p>
        </div>

        <div *ngIf="deviceObj?.deviceInputTypes?.[0] === 'AquilaLisDrive'">
          <div *ngIf="deviceObj?.info?.InternalState?.values?.connectionStatus === 'CONNECTED'; else noChannel">
            <small class="label">Wireless channel number</small>
              <p class="text">{{connectedCoordinatorChannel}}</p>
          </div>
          <ng-template #noChannel>
            <small class="label">Wireless channel number</small>
              <p class="text">-</p>
          </ng-template>
        </div>

        <!-- PAN ID -->
        <div *ngIf="deviceObj?.deviceInputTypes?.[0] === 'AquilaLisDrive'">
          <div *ngIf="deviceObj?.info?.InternalState?.values?.connectionStatus === 'CONNECTED'; else noPanID">
            <small class="label">PanID</small>
              <p class="text">{{connectedCoordinatorPanID}}</p>
          </div>
          <ng-template #noPanID>
            <small class="label">PanID</small>
              <p class="text">-</p>
          </ng-template>
        </div>

        <!-- PAN ID Editing -->
        <div *ngIf="deviceObj?.deviceInputTypes?.[0] === 'AquilaLisCoordinator'">
          <ng-container *ngIf="isEditingPanID; else notEditingPanID">
            &nbsp;&nbsp;
            <form class="inLineElements">
              <input
                  [formControl]="newPanIDFormControl"
                  matInput
                  type="text"
                  id="newPanID"
                  placeholder="{{deviceObj!.configuration['1'].values?.panID}}"
                  required />
              <button
              class="inLineElements action-button"
              type="button"
              (click)="savePanID(newPanIDFormControl.value)"
              mat-flat-button
              color="primary">
                <ion-icon class="inline-block align-middle" name="save-outline" />
                <span class="inline-block align-middle">&nbsp;Save</span>
              </button>
            </form>
          </ng-container>


          <ng-template #notEditingPanID>
            <span>
              <small class="label">PanID</small>
              <ion-button fill="clear" color="dark" size="small" (click)="togglePanIDEdit()">
                <ion-icon class="inline-block align-middle" (click)="togglePanIDEdit()" name="pencil-outline" />
              </ion-button>
            </span>
              <p class="text">{{deviceObj!.configuration['1'].values?.panID}}</p>
          </ng-template>
        </div>

        <div *ngIf="deviceObj?.deviceInputTypes?.[0] === 'AquilaLisDrive' ||
                    deviceObj?.deviceInputTypes?.[0] === 'AquilaCGQSensorPlate' ||
                    deviceObj?.deviceInputTypes?.[0] === 'AquilaBioRSensorPlate'">
          <small class="label">Current state</small>
          <p *ngIf="deviceObj?.info?.InternalState?.values?.ready" class="text">Available</p>
          <p *ngIf="!deviceObj?.info?.InternalState?.values?.ready" class="text">Unavailable</p>
        </div>

        <div>
          <small class="label">Firmware version</small>
          <div *ngIf="this.deviceObj?.info?.InternalState?.values?.connectionStatus !== 'CONNECTED'; else firmwareConnected">
            <p class="text">-</p>
          </div>
          <ng-template #firmwareConnected>
            <div>
              <p class="text">{{deviceObj?.info?.InternalState?.values?.firmware}}</p>
            </div>
          </ng-template>
        </div>

      </div>

      <!-- right column -->
      <div class="grow-[2] flex flex-col justify-start gap-3 leftBorder">
        <div class="rowContainer flex-grow">
          <div class="columnContainer">

            <!-- PT-100 status -->
            <div *ngIf="deviceObj?.deviceInputTypes?.[0] === 'AquilaDotsDO' ||
                    deviceObj?.deviceInputTypes?.[0] === 'AquilaDotsPH' ||
                    deviceObj?.deviceInputTypes?.[0] === 'AquilaDotsPHDO'">
              <small class="label">PT100 status</small>
              <p *ngIf="deviceObj?.info?.InternalState?.values?.connectionStatus !== 'CONNECTED'; else PT100NotConnected" class="text">
                -
              </p>
              <ng-template #PT100NotConnected>
                <p *ngIf="deviceObj?.info?.DOTSModuleStatus?.values?.Pt100State | pureFnCall:containsTrue; else notConnected" class="text">
                  Connected
                </p>
                <ng-template #notConnected>
                  <p class="text">Not connected</p>
                </ng-template>
              </ng-template>
            </div>

            <!-- (Kuhner) Shakers: live data & control -->

            <ng-container *ngIf="deviceObj && deviceObj.devType === 'KuhnerShaker'">
              <ng-container *ngIf="_shakerConnectionInfo">
                <div class="flex flex-nowrap items-center">
                  <div class="flex-shrink-0 mr-3">
                    <small class="block label">IP address</small>
                    <p 
                      *ngIf="!_editingShakerConnectionInfo; else ipInput"
                      class="text"
                    >
                      {{ _shakerConnectionInfo.ip }}
                    </p>

                    <ng-template #ipInput>
                      <app-ipv4-address [(value)]="_newShakerConnectionInfo!.ip"/>
                    </ng-template>
                  </div>
      
                  <div class="flex-shrink">
                    <small class="label block">Port</small>
                    <p
                      *ngIf="!_editingShakerConnectionInfo; else portInput"
                      class="text"
                    >
                      {{ _shakerConnectionInfo.port }}
                    </p>

                    <ng-template #portInput>
                      <mat-form-field class="max-w-[25%] [&>.mat-form-field-wrapper]:!pb-0">
                        <input
                          class="exclude-from-style"
                          matInput
                          type="number"
                          min="0"
                          max="65535"
                          step="1"
                          required
                          [(ngModel)]="_newShakerConnectionInfo!.port" />
                      </mat-form-field>
                    </ng-template>
                  </div>

                  <button
                    *ngIf="!_editingShakerConnectionInfo; else confirmNewShakerConnectionInfo"
                    mat-icon-button
                    (click)="_enableShakerConnectionInfoEdit()"
                    matTooltip="Change IP address & port"
                    class="flex-shrink"
                  >
                    <ion-icon name="pencil-outline" />
                  </button>

                  <ng-template #confirmNewShakerConnectionInfo>
                    <button
                      mat-icon-button
                      (click)="_discardShakerConnectionInfoEdit()"
                      matTooltip="Discard changes"
                      class="flex-shrink"
                    >
                      <ion-icon name="close-circle-outline" />
                    </button>

                    <button
                      mat-icon-button
                      (click)="_applyShakerConnectionInfoEdit()"
                      matTooltip="Apply changes"
                      class="flex-shrink"
                      [disabled]="!_newShakerConnectionInfo?.ip"
                    >
                      <ion-icon name="checkmark-circle-outline" />
                    </button>
                  </ng-template>
                </div>


              </ng-container>


              <div>
                <app-shaker-parameter-control-view [deviceId]="deviceObj._id!"/>
              </div>
            </ng-container>

            <div class="maxWidthNet">
              <small class="inline-block align-middle label">Assigned to teams</small>
              <span [matTooltip]="'You can only see teams you are part of'">
                <ion-icon
                  class="info-icon inline-block align-middle"
                  name="information-circle-outline"></ion-icon>
              </span>
              
              <div *ngIf="deviceObj?.teams?.length === 0">
                <p class="text">
                  -
                </p>
              </div>

              <mat-chip-list class="chip-list">
                <mat-chip
                  *ngFor="let team of _teamInfoList">
                    <span
                      (click)="showTeamDetail(team.id)">
                      {{ team.name }}
                    </span>
                </mat-chip>
              </mat-chip-list>
            </div>

            <div class="mt-auto">
              <a (click)="showDeviceDetail(deviceObj!._id)"
                  class="clickable">
                <p class="smallFont">Open Debugging Information&nbsp;<ion-icon class="open-icon" name="open-outline"></ion-icon></p>
              </a>
            </div>
          
          </div>

          <!-- rightmost column -->
          <div *ngIf="deviceObj" class="max-w-[7em] w-[25%]">
            <img class="illustration" [src]="'assets.illustrations.byDeviceType.' + deviceObj?.deviceInputTypes?.[0] | translate"/>
          </div>

        </div>

      </div>
    </div>
      <div
        *ngIf="deviceObj"
        class="flex flex-row justify-end mr-2.5"
      >

        <!-- Action button container -->
        <div 
          class="save-button-wrapper rowContainer"
          *ngIf="
            deviceObj?.deviceInputTypes?.[0] !== 'AquilaDotsDO' &&
            deviceObj?.deviceInputTypes?.[0] !== 'AquilaDotsPH' &&
            deviceObj?.deviceInputTypes?.[0] !== 'AquilaDotsPHDO'; else batchCalibration
          "
          [matTooltip]="
            (deviceObj|pureFnCall:isDisableButtonFromConnectionState )
            ? 'Actions are disabled when the device is disconnected'
            :''
          "
        >
          <!-- Action button -->
          <app-split-button
            #actionsButton
            (click)="actionsButton.open()"
            [disabled]="deviceObj | pureFnCall:isDisableButtonFromConnectionState">
            <ng-container buttonContent>
              {{ selectedActionDisplayText }}
            </ng-container>

            @for(action of _deviceActions; track action.key) {
              <button
                mat-menu-item
                (click)="selectAction(action.key); executeSelectedAction();"
              >
                {{ action.displayText }}
              </button>
            }

          </app-split-button>
        </div>

        <ng-template #batchCalibration>
          <div>
            <button
              mat-raised-button
              color="primary"
              [routerLink]="['/dots-calibration']"
              matDialogClose>
              Batch Calibration
            </button>
          </div>
        </ng-template>
      </div>
  </div>
</ng-template>
