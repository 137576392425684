import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { filter, first, timeout } from 'rxjs/operators';
import { IPv4AddressComponent } from 'src/app/components/input-controls/ipv4-address/ipv4-address.component';
import { MaterialImportsModule } from 'src/app/material-imports.module';
import { IPojoDevice } from 'src/app/model/device/IPojoDevice';
import { DeviceService } from 'src/app/services/device/device.service';
import { LogService } from 'src/app/services/loggers/logger.service';
import { UINotificationService } from 'src/app/services/ui-notification/uinotification.service';
import { DefaultConfig } from 'src/assets/default.config';
import { ICanCreateDevice } from '../../can-create-device.interface';

@Component({
  selector: 'app-kuhner-device-object-provider',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MaterialImportsModule,
    IPv4AddressComponent
  ],
  templateUrl: './kuhner-device-object-provider.component.html',
  styles: [
    ':host { display: block; }'
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class KuhnerDeviceObjectProviderComponent implements ICanCreateDevice {
  canCreateDevice = new EventEmitter<boolean>();


  /**
   * @private
   */
  _ipAddress: string | null = null;


  /**
   * @private
   */
  _port: number | null = null;



  private deviceService = inject(DeviceService);


  private changeDetectorRef = inject(ChangeDetectorRef);


  private uiNotifications = inject(UINotificationService);


  private log = inject(LogService);


  /**
   * Checks the provided values and if valid, emits a new device object.
   */
  _checkAndEmit() {
    this.limitPortToBounds();

    // Explicit null-check for port because could be 0
    if (!this._ipAddress || this._port === null) {
      this.canCreateDevice.emit(false);
      return;
    }

    this.canCreateDevice.emit(true);
  }


  private limitPortToBounds() {
    if (this._port === null) {
      return;
    }

    this.changeDetectorRef.detectChanges();

    if (this._port % 1 !== 0) {
      this._port = Math.floor(this._port);
    }

    if (this._port < 0) {
      this._port = 0;
    }

    if (this._port > 65535) {
      this._port = 65535;
    }
  }


  async createDevice(): Promise<boolean> {
    if (!this._ipAddress || this._port === null) {
      throw new Error('Invalid device values when trying to add TCP-connected Kuhner shaker');
    }

    // Subscribe to the device list until we run into a timeout (error, rejects promise) or until
    // ad device matching the ip and port is found.
    // We are not interested in the list itself, just in the fact that the device is found.
    const foundDevice$ = lastValueFrom(this.deviceService.getDevices()
      .pipe(
        filter(list => list.some(device => this.isWantedDevice(device))),
        first(),
        timeout(DefaultConfig.device.addModbusTcpConnectionTimeoutSeconds * 1000)
      ));


    // Submit add-connection request
    await this.deviceService.addModbusTcpConnection({
      deviceType: 'KuhnerShaker',
      ipAddress: this._ipAddress,
      port: this._port
    });


    try {
      // Wait for the device to appear in the list or timeout
      await foundDevice$;

      this.uiNotifications.displaySnackbar({
        message: 'Device added successfully',
        durationMilliseconds: DefaultConfig.uiNotifications.defaultToastDurationMilliseconds
      });

      return true;
    } catch (e) {
      this.log.error('Device did not appear in the list after adding the connection', e);
      this.uiNotifications.displayErrorAlert(
        {
          message: 'Device was not detected in time. Please make sure the shaker is turned on and reachable within the network.',
          title: 'Timeout when adding device',
        }, e
      );

      return false;
    }
  }


  private isWantedDevice(device: IPojoDevice): boolean {
    for (const key of Object.keys(device.configuration)) {
      const item = device.configuration[key];

      if (item.type === 'TcpConnection') {
        if (item.values?.ipAddress === this._ipAddress && item.values?.port === this._port) {
          return true;
        }
      }
    }

    return false;
  }
}
