import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter } from '@angular/core';
import { IonicImports } from 'src/app/material-imports.module';
import { ICanCreateDevice } from '../../can-create-device.interface';


/**
 * Component used for displaying an info message regarding USB devices.
 *
 * Will never emit any device objects.
 */
@Component({
  selector: 'app-generic-usb-device-object-provider',
  standalone: true,
  imports: [
    IonicImports,
  ],
  template: `
    <p class="flex items-center gap-2">
      <ion-icon name="alert-circle-outline" />
      <span>USB devices are auto-detected and don't need to be added manually.</span>
    </p>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericUsbDeviceObjectProviderComponent implements ICanCreateDevice, AfterViewInit {

  /**
   * @returns Promise resolving to `true`
   */
  createDevice(): Promise<boolean> {
    return Promise.resolve(true);
  }


  /**
   * Emits `false` once after the view has been initialized (in AfterViewInit).
   */
  canCreateDevice = new EventEmitter<boolean>();


  ngAfterViewInit(): void {
    this.canCreateDevice.emit(false);
  }
}
