<app-menu-bar [viewTitle]="_menuBarTitle"></app-menu-bar>
<div class="dashboard-block">

  <div class="relative w-full shrink-0 h-[82px]">

    <div class="absolute absolutely-centered">
      <mat-button-toggle-group
        [value]="_mode"
        (change)="changeMode($event)"
        class="
          objects-devices-toggle
          mat-button-toggle-group-large
          mat-primary
        "
      >
        <mat-button-toggle value="thingies">Objects</mat-button-toggle>
        <mat-button-toggle value="devices">Devices</mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div 
      class="absolute right-0 top-1/2 -translate-y-1/2"
      [class.hidden]="_mode !== 'devices'"
    >
      <button
        mat-stroked-button
        (click)="_openAddDeviceDialog()"
      >
        <ion-icon name="add-outline" />
        Add Device
      </button>
    </div>
  </div>
  
  <ng-container *ngIf="_mode === 'thingies' || _mode === 'cards'">
    <app-list-thingies
      class="list"
      [groupByExperimentChecked]="groupByExperimentChecked"
      [refreshListSignal]="refreshListSignal"
      [viewMode]="viewMode"
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [(selectedTab)]="_selectedThingieTab"
    ></app-list-thingies>
  </ng-container>
  <ng-container *ngIf="_mode === 'devices'">
    <app-list-devices
      class="list"
      [(selectedTab)]="_selectedDeviceTab"
    ></app-list-devices>
  </ng-container>
</div>
