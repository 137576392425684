import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ComponentRef, Injectable, OnDestroy, Type, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IonicImports, MaterialImportsModule } from 'src/app/material-imports.module';
import { ICanCreateDevice } from './can-create-device.interface';
import { GenericUsbDeviceObjectProviderComponent } from './device-object-providers/generic-usb-device-object-provider/generic-usb-device-object-provider.component';
import { KuhnerDeviceObjectProviderComponent } from './device-object-providers/kuhner-device-object-provider/kuhner-device-object-provider.component';


export type AddDeviceDialogResult = void;


type AvailableType = {
  type: string;
  text: string;

  /**
   * The component which will query the user for the required data
   * and returns the device object.
   */
  component: Type<ICanCreateDevice>;
};


/**
 * Dialog component for adding new devices.
 *
 * Depending on the selected device type, a different component will be loaded
 * which will handle the creation of the device.
 *
 * Those sub-components must implement the {@link ICanCreateDevice} interface.
 */
@Component({
  selector: 'app-add-device-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MaterialImportsModule,
    IonicImports,
  ],
  templateUrl: './add-device-dialog.component.html',
  styleUrls: ['./add-device-dialog.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddDeviceDialogComponent implements OnDestroy {

  /**
   * @private
   */
  @ViewChild('queryComponentHost', { static: true, read: ViewContainerRef })
  _queryComponentHost?: ViewContainerRef;


  private self = inject<MatDialogRef<AddDeviceDialogComponent, AddDeviceDialogResult>>(MatDialogRef);


  private changeDetector = inject(ChangeDetectorRef);


  // TODO: take from backend?
  /**
   * @private
   */
  _deviceTypesAvailable$ = new BehaviorSubject<AvailableType[]>([
    {
      type: 'KuhnerShakerLTX',
      text: 'Kuhner LT-X',
      component: KuhnerDeviceObjectProviderComponent
    },
    {
      type: 'otherUsb',
      text: 'USB device',
      component: GenericUsbDeviceObjectProviderComponent
    }
  ]);


  private subscription?: Subscription;


  /**
   * @private
   */
  _canCreateDevice$ = new BehaviorSubject<boolean>(false);


  private componentRef?: ComponentRef<ICanCreateDevice>;


  /**
   * @private
   *
   * Used to disable the button and show progress indication while creating the device.
   */
  _processing$ = new BehaviorSubject<boolean>(false);


  /**
   * @private
   */
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }


  /**
   * @private
   */
  async _onAddDevice() {
    if (!this.componentRef || !this._canCreateDevice$.value) {
      return;
    }

    try {
      this._processing$.next(true);

      // Close dialog only when device creation was successful
      if (await this.componentRef?.instance.createDevice()) {
        this.self.close();
      }
    } finally {
      this._processing$.next(false);
    }
  }


  /**
   * User-input handler for device type selection.
   *
   * @private
   */
  _onSelectionChange(change: string) {
    const selected = this._deviceTypesAvailable$.value.find(t => t.type === change);
    if (!selected) {
      return;
    }

    this.subscription?.unsubscribe();
    this.subscription = undefined;

    if (selected && selected.component) {
      this._queryComponentHost!.clear();
      this.componentRef = undefined;
      this.componentRef = this._queryComponentHost!.createComponent(selected.component);

      this.subscription = this.componentRef.instance.canCreateDevice
        .subscribe(canCreate => {
          this._canCreateDevice$.next(canCreate);
          this.changeDetector.detectChanges();  // TODO: not needed anymore, because subscription in template
        });
    }
  }
}


/**
 * Utility service for opening a dialog to add new devices.
 *
 * {@link AddDeviceDialogComponent}
 */
@Injectable({ providedIn: 'root' })
export class AddDeviceDialogComponentService {
  private dialog = inject(MatDialog);

  open() {
    return this.dialog.open<
      AddDeviceDialogComponent,
      void,
      AddDeviceDialogResult>(
      AddDeviceDialogComponent,
      {
        panelClass: '7f763fbc5bc24378b996-adddevicedialog',
        minWidth: 800,
      }
    );
  }
}
