import { Injectable } from '@angular/core';
import { Observer, PartialObserver, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IEvent } from 'src/app/model/events/base-event';
import { EventService } from '../event.service';

type ClassType<T> = new (...args: any[]) => T;

@Injectable()
export class EventServiceImpl implements EventService {
  private subject = new Subject<any>();

  constructor() { }

  subscribe<T extends IEvent>(
    eventType: ClassType<T>,
    callback: Observer<T> | PartialObserver<T> | ((ev: T) => void)
  ): Subscription {
    return this.subject
      .asObservable()
      .pipe(
        filter(event => event instanceof eventType)
      )
      .subscribe(callback as any);
  }

  broadcast<T extends IEvent>(event: T): void {
    this.subject.next(event);
  }
}
