import { Injectable } from '@angular/core';
import { ITeam, TeamRole } from 'src/app/model/user-management/team';
import { IUser } from 'src/app/model/user-management/user';
import { TeamServiceImpl } from './implementation/team.service-impl';

export interface IGroupedUsers {
  leaders: IUser[];
  members: IUser[];
}

export interface ITeamChanges extends Omit<
  ITeam,
'_id'
// TODO: omit the following, too,
// once https://aquila-biolabs.atlassian.net/browse/COSOFT-1180
// is done:
// | 'creator'
| 'createdAt'
> {
  createdAt: string | number;
}

/**
 * TeamService
 *
 * Notes
 *
 * - operations can only be performed on
 * entitites the acting user access to with
 * the required permissions for that operation.
 * - only data that the acting user has permission
 * to access can be acquired.
 */
@Injectable({
  providedIn: 'root',
  useClass: TeamServiceImpl
})
export abstract class TeamService {

  /**
   * Acquire a list of all teams
   */
  abstract getTeamList(): Promise<ITeam[]>;

  /**
   * Acquire a list of requested team objects
   */
  abstract getTeamListByIds(ids: string): Promise<ITeam[]>;


  /**
   * Request specific team
   *
   * @param opts - Object containing the team id to be looked up.
   */
  abstract getTeamById(opts: { teamId: string }): Promise<ITeam>;


  /**
   * Obtain all users who are part of the team.
   *
   */
  abstract getUsersInTeam(opts: { teamId: string }): Promise<IUser[]>;

  /**
   * For a team, acquire a list of all members,
   * grouped by their role in that particular team.
   *
   * @param opts - Either a team ID or a team object
   */
  abstract getUsersByTeamGroupedByRole(opts: {
    teamId: string;
  }): Promise<IGroupedUsers>;

  /**
   * Create new team
   *
   * @param team - The team to create
   * @returns - A promise containing the newly-created team
   */
  abstract createTeam(team: { name: string; archived: boolean }): Promise<ITeam>;

  /**
   * Update a team
   *
   * @param opts
   */
  abstract updateTeam(opts: {
    teamId: string;
    changes: ITeamChanges;
  }): Promise<ITeam>;

  /**
   * Add user to team in a given role
   *
   * @param opts
   */
  abstract addUserToTeamInRole(opts: {
    user: IUser | string;
    team: ITeam | string;
    role: TeamRole;
  }): Promise<void>;

  /**
   * Remove user in role from team
   *
   * @param opts
   */
  abstract removeUserInRoleFromTeam(opts: {
    user: IUser | string;
    team: ITeam | string;
    role: TeamRole;
  }): Promise<void>;
}
