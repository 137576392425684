import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TaskKey } from 'src/app/model/backend';
import { IModbusTcpConnectionDTO } from 'src/app/model/device/IModbusTcpConnectionDTO';
import { IPojoDevice } from 'src/app/model/device/IPojoDevice';
import { DeviceSeries } from 'src/app/model/device/device-series';
import { ShakerFeatureDescriptor } from 'src/app/model/shaker-control/shaker-feature-descriptor';
import { IDevice } from 'src/app/model/thingie/task';
import { DeviceServiceImpl } from './implementation/device.service';

export interface IDataInventoryResponse {
  _id: {
    keyType: 'deviceInput';
    device: string;
    type: string;
    config: { [k: string]: any };
  };
  first: string;
  last: string;
  nsamples: number;
}

///////////////////////////////////////////////////////////////
// end of autogenerated part

export interface IDeviceTemplate {
  version: string;
  created: string;
  type: 'deviceDescription';
  device: IPojoDevice;
}

export type IDeviceResourceCheck = Pick<IPojoDevice, 'address' | 'name' | 'configuration' | 'blockedBy' | 'resources'>;

@Injectable({
  providedIn: 'root',
  useClass: DeviceServiceImpl
})
export abstract class DeviceService {
  constructor() { }

  abstract getDevice(address: string, writable?: boolean): Observable<IPojoDevice>;

  /**
   * Obtain device list.
   *
   * Will emit an updated device list when
   * new devices are added.
   */
  abstract getDevices(): Observable<IPojoDevice[]>;
  abstract getDevicesByTeam(teamID: string): Observable<IPojoDevice[]>;
  abstract postDevice(device: IPojoDevice): Observable<IPojoDevice>;
  abstract createDevice(device: IPojoDevice): Observable<IPojoDevice>;
  abstract getDeviceActions(deviceId: string): Observable<string[]>;
  abstract executeDeviceAction(deviceId: string, action: string, parameters?: any): Observable<any>;
  abstract getDeviceTemplate(version: string): Observable<IDeviceTemplate>;
  abstract getDataInventory(address: string): Observable<IDataInventoryResponse>;
  abstract deleteConfig(deviceAddress: string, handleId: string): Promise<IPojoDevice>;

  /**
   * Check resource usage of a device
   *
   * @param device The device to check
   */
  abstract checkResources(device: IDeviceResourceCheck): Promise<IDeviceResourceCheck | Error>;

  /**
   * Check the resource usage of a list of devices
   *
   * @param deviceList The list of devices to check
   */
  abstract checkResources(deviceList: IDeviceResourceCheck[]): Promise<(IDeviceResourceCheck | Error)[]>;

  abstract subscribeUpdates(deviceId?: string): Observable<any>;
  abstract forceUnblockDevice(deviceId: string, taskkey?: TaskKey): Observable<IPojoDevice>;

  /**
   * Disable device
   *
   * @param opts
   * @returns The device object after modification
   */
  abstract disableDevice(opts: { address: string }): Promise<IPojoDevice>;

  /**
   *
   * @param opts
   * @returns The device object after modification
   */
  abstract enableDevice(opts: { address: string }): Promise<IPojoDevice>;

  /**
   *
   * @param device - The device's ID or address
   * @returns A list of device data series information
   */
  abstract getDeviceDataSeries(device: string): Promise<DeviceSeries[]>;

  /**
   * Upload a configuration to a device, specified by a Device Task Input
   *
   * @param res TaskInput for the Device
   */
  abstract uploadConfig(res: IDevice): Promise<IPojoDevice>;

  /**
   * Totally reset a device
   */
  abstract resetDevice(deviceId: string): Promise<void>;

  abstract archiveDevice(deviceId: string): Promise<void>;

  abstract unarchiveDevice(deviceId: string): Promise<void>;

  abstract getShakerFeatures(): Promise<ShakerFeatureDescriptor[]>;

  //// Modbus TCP connections

  abstract addModbusTcpConnection(data: IModbusTcpConnectionDTO): Promise<void>;
  abstract removeModbusTcpConnection(data: IModbusTcpConnectionDTO): Promise<void>;
  abstract updateModbusTcpConnection(data: { address: string; dto: IModbusTcpConnectionDTO }): Promise<void>;
}
