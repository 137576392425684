import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialImportsModule } from 'src/app/material-imports.module';

/**
 * A component to input an IPv4 address.
 *
 * - The input is split into four parts, each part being a number between 0 and 255.
 * - The input will be validated and errors will be shown if the input is not a valid IPv4 address.
 * - The input will be emitted as a string, with the parts separated by dots.
 * - The input will not strip leading zeros from each part.
 */
@Component({
  selector: 'app-ipv4-address',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialImportsModule
  ],
  templateUrl: './ipv4-address.component.html',
  styles: [
    ':host { display: inline-block; }',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IPv4AddressComponent {

  /**
   * @private
   */
  @ViewChildren('part', { read: ElementRef<HTMLElement> })
  _inputs?: QueryList<ElementRef<HTMLInputElement>>;


  /**
   * Will take a string, which should be a valid IPv4 address and sets the
   * input accordingly.
   *
   * If it's not a valid IPv4 address, will try to parse up to the first
   * four dot-separated values, ignore the rest and show errors if some are found.
   *
   * Will remove leading zeros from each part, but not re-emit the value if it does.
   */
  @Input()
  set value(value: string | null) {
    if (value === null) {
      // Don't reset existing values
      return;
    }

    this._parts = value.split('.').slice(0, 4);
    this.checkParts();
  }


  /**
   * Either a valid IPv4 address or `null`.
   */
  @Output()
  valueChange = new EventEmitter<string | null>();


  /**
   * @private
   */
  _parts: (string | number | null)[] = [null, null, null, null];


  /**
   * Errors of individual IP parts.
   *
   * Used to apply the error styling to boxes.
   *
   * @private
   */
  _errors: boolean[] = [ false, false, false, false ];


  private changeDetectorRef = inject(ChangeDetectorRef);


  /**
   * Handler, notified by each input, when its value changes.
   *
   * @returns
   */
  _onPartChange(): void {
    // Ensure the change detector realizes a change, because CD is run
    // after this callback.
    this.changeDetectorRef.detectChanges();

    this.checkParts();

    if (this._parts.some(part => part === null)) {
      this.valueChange.emit(null);
      return;
    }

    const valToEmit = this._parts.join('.');
    this.valueChange.emit(valToEmit);

    this.changeDetectorRef.markForCheck();
  }


  /**
   * Checks parts & updates their error states.
   *
   * Will also remove leading zeros from each part.
   */
  private checkParts() {
    // Make sure all 4 parts are within valid IPv4 range
    this._parts = this._parts.map((part, idx) => {
      if (part === null) {
        this._errors[idx] = true;
        return null;
      }

      const num = parseInt(String(part).substring(0, 3), 10);
      if (isNaN(num) || num < 0 || num > 255) {
        this._errors[idx] = true;
        return part.toString().substring(0, 3);  // Leave as is
      } else {
        this._errors[idx] = false;
        return num.toString().substring(0, 3);
      }
    });
  }


  /**
   * @private
   */
  get _hasError() {
    return this._errors.some(e => e);
  }


  /**
   * @private
   * @param event
   * @param idx
   */
  _onKeyUp(event: KeyboardEvent, idx: number) {
    // Check if pressed key was a number
    if (event.key.length === 1 && event.key.match(/[0-9]/) !== null) {
      // Move focus to the next input field
      const target = event.target as HTMLInputElement;
      if (target.value.length === 3 && idx < 3) {
        const elem = this._inputs?.get(idx + 1)?.nativeElement;
        elem?.focus();
        elem?.select();
      }
    }
  }
}
