import { Pipe, PipeTransform } from '@angular/core';

/**
 * This pipe is used to call a member function of an object in a template
 * with angular's pure pipe "strategy", meaning the function would only
 * be called once initially and then only when the input to the pipe changes.
 *
 * Consider using observables or a non-default change detection strategy instead.
 */
@Pipe({
  name: 'pureFnCall',
  pure: true
})
export class PureFunctionCallPipe implements PipeTransform {
  transform<T>(value: unknown, memberFunction: (...args: any[]) => T, ...args: unknown[]): T {
    return memberFunction(value, ...args);
  }
}
