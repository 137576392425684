import { combineLatest, ConnectableObservable, defer, fromEvent, interval, MonoTypeOperatorFunction, Observable, Subscription } from 'rxjs';
import { bufferWhen, debounce, filter, first, map, publishReplay, share, startWith, throttle, ThrottleConfig } from 'rxjs/operators';

export function bufferWhileTabIsHidden<T>() {
  return (source: Observable<T>) => {
    const tabVisibilityEvent$ = fromEvent(document, 'visibilitychange').pipe(
      map(() => document.hidden),
      startWith(document.hidden)
    );
    // combineLatest these observables, such that if the tab is
    // hidden, updates are filtered out. If the tab is activated, the
    // latest obj was cached and will be returned immediately
    return combineLatest([source, tabVisibilityEvent$]).pipe(
      // only output when the tab is visible
      filter(([,hidden]) => hidden === false),
      // output the source observable object
      map(([obj,]) => obj),
    );
  };
}

/**
 * Make a ConnectableObservable behave like a ordinary observable and automates the way you can connect to it.
 *
 * Like the original refCount operator, but disconnects the source observable only after a delay has passed.
 *
 * @param delay delay in ms after which to unsubscribe the source
 * @returns a shared version of the source Observable that automatically connects to the source observable
 *    and disconnects when no subscribers are left **and** the delay has passed.
 */
export function delayedRefCount<T>(delay: number) {
  return function transform(obs: Observable<T>) {
    const connectableObs = obs as ConnectableObservable<T>;

    if (connectableObs.connect === undefined) {
      throw new Error('Trying to apply delayedRefCount operator to an observable that is no ConnectableObservable.');
    }

    let subscribers = 0;
    let connection: Subscription | undefined;
    let timeout: ReturnType<typeof setTimeout> | undefined;

    return new Observable<T>((subscriber) => {
      subscribers++;

      if (timeout !== undefined) {
        // cancel pending unsubscribe
        clearTimeout(timeout);
        timeout = undefined;
      }

      // Begin emitting values
      if (connection === undefined) {
        connection = connectableObs.connect();
      }

      connectableObs.subscribe(subscriber);

      return function teardown() {
        if (--subscribers === 0) {
          timeout = setTimeout(
            () => {
              if (connection !== undefined) {
                connection.unsubscribe();
                connection = undefined;
              }

              timeout = undefined;
            },
            delay
          );
        }
      };
    });
  };
}

/**
 * Like the original shareReplay operator, but uses delayedRefCount
 *
 * @param delay delay in ms after which to unsubscribe the source
 * @param buffer number of buffered elements
 * @returns a shareReplay'd version of the source observable that only resets after a set
 *   delay time without subscribers
 */
export function delayedShareReplay<T>(delay: number, buffer: number) {
  return (obs: Observable<T>) => obs.pipe(publishReplay(buffer), delayedRefCount(delay));
}

export function onSubscribe<T>(f: () => void): MonoTypeOperatorFunction<T> {
  return function inner(obs: Observable<T>): Observable<T> {
    return defer(() => {
      f();
      return obs;
    });
  };
}

/**
 * This is a map of reusable `[n, interval(n)]` entries. The reason is that due to zone.js,
 * every timer tick triggers change detection, and we want to reduce the number of
 * unnecessary change detection runs. So we want to reduce the number of timers that run,
 * hence reduce the number of instantiations of `interval` observables (or similar).
 */
const tickMap = new Map<number, Observable<any>>();

/**
 * Like bufferTime, but uses a timer from the reusable tickMap map. This means that the
 * first buffer interval might be shorter than expected, because the timer was already
 * started before the returned observable is subscribed.
 *
 * Don't use this function if the exact timing of the first batch is important.
 *
 * @param _interval buffering interval
 * @returns Operator that turns observable into bufferTime'd observable, reusing the timer if possible
 */
export function bufferTimeBatched<T>(_interval: number) {
  return function transform(obs: Observable<T>) {
    return obs.pipe(
      bufferWhen(() => {
        if (!tickMap.has(_interval)) {
          tickMap.set(_interval, interval(_interval).pipe(share()));
        }
        return tickMap.get(_interval)!.pipe(first());
      })
    );
  };
}

/**
 * Like debounceTime, but uses a timer from the reusable tickMap map. This means that the
 * first debounce interval might be shorter than expected, because the timer was already
 * started before the returned observable is subscribed.
 *
 * Don't use this function if the exact timing of the first debounce is important.
 *
 * @param _interval debounce interval
 * @returns Operator that turns observable into debounceTime'd observable, reusing the timer if possible
 */
export function debounceTimeBatched<T>(_interval: number) {
  return function transform(obs: Observable<T>) {
    return obs.pipe(
      debounce(() => {
        if (!tickMap.has(_interval)) {
          tickMap.set(_interval, interval(_interval).pipe(share()));
        }
        return tickMap.get(_interval)!.pipe(first());
      })
    );
  };
}


/**
 * Like throttleTime, but uses a timer from the reusable tickMap map. This means that the
 * first throttle interval might be shorter than expected, because the timer was already
 * started before the returned observable is subscribed.
 *
 * Don't use this function if the exact timing of the first throttle period is important.
 *
 * @param _interval throttling interval
 * @param config throttleTime config
 * @returns Operator that turns observable into throttleTime'd observable, reusing the timer if possible
 */
export function throttleTimeBatched<T>(_interval: number, config?: ThrottleConfig) {
  return function transform(obs: Observable<T>) {
    return obs.pipe(
      throttle(() => {
        if (!tickMap.has(_interval)) {
          tickMap.set(_interval, interval(_interval).pipe(share()));
        }
        return tickMap.get(_interval)!.pipe(first());
      }, config)
    );
  };
}
