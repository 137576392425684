<div class="
    relative
    border rounded border-solid border-gray-300
    px-4 py-2
    hover:border-gray-300 hover:shadow-[0px_1px_2px_0px_rgba(0,0,0,0.30),0px_2px_6px_2px_rgba(0,0,0,0.15)]
    focus-within:!border-blue-500 focus-within:!shadow-[0_2px_2px_0] focus-within:!shadow-blue-200
  "
  [class.!border-error-red-500]="_hasError"
>
  <input
    #part
    class="max-w-[2em] outline-none border-none shadow-none placeholder:text-gray-300"
    type="number"
    min="0"
    max="255"
    step="1" 
    placeholder="XXX"
    [(ngModel)]="_parts[0]"
    (ngModelChange)="_onPartChange()"
    (keyup)="_onKeyUp($event, 0)" />

  <span>.</span>

  <input
    #part
    class="max-w-[2em] outline-none border-none shadow-none placeholder:text-gray-300 text-end"
    type="number"
    min="0"
    max="255"
    step="1" 
    placeholder="XXX"
    [(ngModel)]="_parts[1]"
    (ngModelChange)="_onPartChange()"
    (keyup)="_onKeyUp($event, 1)" />

  <span>.</span>

  <input
    #part
    class="max-w-[2em] outline-none border-none shadow-none placeholder:text-gray-300 text-end"
    type="number"
    min="0"
    max="255"
    step="1" 
    placeholder="XXX"
    [(ngModel)]="_parts[2]"
    (ngModelChange)="_onPartChange()"
    (keyup)="_onKeyUp($event, 2)" />


  <span>.</span>

  <input
    #part
    class="max-w-[2em] outline-none border-none shadow-none placeholder:text-gray-300 text-end"
    type="number"
    min="0"
    max="255"
    step="1" 
    placeholder="XXX"
    [(ngModel)]="_parts[3]"
    (ngModelChange)="_onPartChange()"
    (keyup)="_onKeyUp($event, 3)" />

    <div 
      class="absolute bottom-0 left-0 translate-y-full"
      [class.hidden]="!_hasError"
    >
      <mat-error class="text-nowrap text-[12px]">Invalid IPv4</mat-error>
    </div>
</div>

