import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { lastValueFrom } from 'rxjs';
import { ITeam, TeamRole } from 'src/app/model/user-management/team';
import { IUser } from 'src/app/model/user-management/user';
import { DefaultConfig } from 'src/assets/default.config';
import { UserService } from '../../user/user.service';
import { IGroupedUsers, ITeamChanges, TeamService } from '../team.service';

interface ITeamDTO extends Omit<ITeam, 'createdAt'> {
  createdAt: string;
}
type ITeamCreateDTO = Omit<ITeam, '_id' | 'createdAt' | 'creator'>;

@Injectable({
  providedIn: 'root'
})
export class TeamServiceImpl implements TeamService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
  ) {

  }

  async getTeamList(): Promise<ITeam[]> {
    const uri = DefaultConfig.teamManagement.team;

    const dtoList = (await lastValueFrom(this.http.get<ITeamDTO[]>(
      uri
    )))!;

    return this.convertDtoListToTeamList(dtoList);
  }


  async getTeamListByIds(ids: string): Promise<ITeam[]> {
    const uri = DefaultConfig.teamManagement.teamsByIds.replace('{teamIDs}', encodeURIComponent(ids));

    const dtoList = (await lastValueFrom(this.http.get<ITeamDTO[]>(uri)))!;

    return this.convertDtoListToTeamList(dtoList);
  }

  async getTeamById(opts: { teamId: string }): Promise<ITeam> {
    const dto = (await lastValueFrom(this.http.get<ITeamDTO>(
      DefaultConfig.teamManagement.team + '/' + encodeURIComponent(opts.teamId)
    )))!;

    return this.convertDtoToTeam(dto);
  }

  async getUsersInTeam(opts: { teamId: string }): Promise<IUser[]> {
    const userList = await this.userService.getUserList();

    const usersInTeam: IUser[] = [];
    for (const user of userList) {
      // Check if the user is in the team specified
      for (const team of Object.keys(user.teams)) {
        if (team === opts.teamId) {
          usersInTeam.push(user);
          break;
        }
      }
    }

    return usersInTeam;
  }

  async getUsersByTeamGroupedByRole(opts: {
    teamId: string;
  }): Promise<IGroupedUsers> {
    const usersInTeam = await this.getUsersInTeam({ teamId: opts.teamId });

    //// Group users

    const groupedUsers: IGroupedUsers = {
      leaders: [],
      members: []
    };

    for (const user of usersInTeam) {
      if (user.teams[opts.teamId] === 'LEADER') {
        groupedUsers.leaders.push(user);
      } else {
        groupedUsers.members.push(user);
      }
    }

    return groupedUsers;
  }

  async createTeam(team: ITeamCreateDTO): Promise<ITeam> {
    return (await lastValueFrom(this.http.post<ITeam>(
      DefaultConfig.teamManagement.team,
      team
    )))!;
  }

  async updateTeam(opts: { teamId: string; changes: ITeamChanges }): Promise<ITeam> {
    return (await lastValueFrom(this.http.put<ITeam>(
      DefaultConfig.teamManagement.team + '/' + encodeURIComponent(opts.teamId),
      {
        _id: opts.teamId,
        ...opts.changes
      }
    )))!;
  }

  async addUserToTeamInRole(opts: {
    user: IUser | string;
    team: string | ITeam;
    role: TeamRole;
  }): Promise<void> {
    //// Determine targeted team's ID

    let teamId: string;
    if (typeof opts.team === 'object') {
      if (opts.team._id === undefined) {
        throw new Error(`Team's ID has to be defined!`);
      }

      teamId = opts.team._id;
    } else {
      teamId = opts.team;
    }


    //// Determine user id

    let userId: string;
    if (typeof opts.user === 'object') {
      if (opts.user._id === undefined) {
        throw new Error(`User's ID has to be defined!`);
      }
      userId = opts.user._id;
    } else {
      userId = opts.user;
    }


    //// Build the uri depending on the role

    let uri;
    if (opts.role === 'LEADER') {
      uri = DefaultConfig.teamManagement.leader;
    } else {
      uri = DefaultConfig.teamManagement.member;
    }

    uri = uri
      .replace('{id}', encodeURIComponent(teamId))
      .replace('{userId}', encodeURIComponent(userId));


    (await lastValueFrom(this.http.post(
      uri,
      undefined
    )))!;
  }

  async removeUserInRoleFromTeam(opts: {
    user: IUser | string;
    team: string | ITeam;
    role: TeamRole;
  }): Promise<void> {
    //// Determine targeted team's ID

    let teamId: string;
    if (typeof opts.team === 'object') {
      if (opts.team._id === undefined) {
        throw new Error(`Team's ID has to be defined!`);
      }

      teamId = opts.team._id;
    } else {
      teamId = opts.team;
    }


    //// Determine user id

    let userId: string;
    if (typeof opts.user === 'object') {
      if (opts.user._id === undefined) {
        throw new Error(`User's ID has to be defined!`);
      }
      userId = opts.user._id;
    } else {
      userId = opts.user;
    }


    //// Prepare the uri according to role

    let uri;
    if (opts.role === 'LEADER') {
      uri = DefaultConfig.teamManagement.leader;
    } else {
      uri = DefaultConfig.teamManagement.member;
    }

    uri = uri
      .replace('{id}', encodeURIComponent(teamId))
      .replace('{userId}', encodeURIComponent(userId));


    // Do it
    (await lastValueFrom(this.http.delete(uri)))!;
  }

  private convertDtoToTeam(dto: ITeamDTO): ITeam {
    return {
      _id: dto._id,
      archived: dto.archived,
      createdAt: DateTime.fromISO(dto.createdAt),
      creator: dto.creator,
      name: dto.name
    };
  }

  private convertDtoListToTeamList(dtoList: ITeamDTO[]): ITeam[] {
    return dtoList.map(dto => this.convertDtoToTeam(dto));
  }
}
