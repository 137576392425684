import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import DataLoader from 'dataloader';
import { LRUMap } from 'lru_map';
import hash from 'object-hash';
import { Observable, combineLatest, from, lastValueFrom, merge, of, throwError } from 'rxjs';
import { finalize, map, startWith, switchMap, take } from 'rxjs/operators';
import { TaskKey } from 'src/app/model/backend';
import { IModbusTcpConnectionDTO } from 'src/app/model/device/IModbusTcpConnectionDTO';
import { IPacketContentItem } from 'src/app/model/device/IPacketContentItem';
import { IPojoDevice } from 'src/app/model/device/IPojoDevice';
import { DeviceSeries } from 'src/app/model/device/device-series';
import { ShakerFeatureDescriptor } from 'src/app/model/shaker-control/shaker-feature-descriptor';
import { IDevice } from 'src/app/model/thingie/task';
import { IDataEvent, IDeviceFilter, IFilter, WebsocketConnectionService } from 'src/app/services/common/websocket-connection.service';
import { deepCopy } from 'src/app/utility/deep-copy';
import { deepFreeze } from 'src/app/utility/deep-freeze';
import { createDefaultDeviceDataSeries } from 'src/app/utility/default-object-creator';
import { addConfigItemFromInputToDevice } from 'src/app/utility/device-configuration';
import { bufferWhileTabIsHidden, delayedShareReplay } from 'src/app/utility/rxjs';
import { DefaultConfig } from 'src/assets/default.config';
import { SubSink } from 'subsink';
import { DeviceService, IDataInventoryResponse, IDeviceResourceCheck, IDeviceTemplate } from '../device.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceServiceImpl implements DeviceService, OnDestroy {
  calculateResourceUsageUrl = DefaultConfig.uris.calculateResourceUsage;
  cocoTemplateUrl           = DefaultConfig.uris.deviceTemplateCoco;
  createDeviceUrl           = DefaultConfig.uris.createDevice;
  dataInventoryUrl          = DefaultConfig.uris.dataInventory;
  deviceActionExecution     = DefaultConfig.uris.deviceActionExecution;
  deviceActions             = DefaultConfig.uris.deviceActions;
  disableDeviceUri          = DefaultConfig.uris.deviceDisablePart;
  deviceForceUnblock        = DefaultConfig.uris.deviceForceUnblock;
  deviceForceUnblockAll     = DefaultConfig.uris.deviceForceUnblockAll;
  deviceListUrl             = DefaultConfig.uris.deviceList;
  deviceListUrlByTeam       = DefaultConfig.uris.deviceListByTeam;
  deviceUrl                 = DefaultConfig.uris.device;
  enableDeviceUri           = DefaultConfig.uris.deviceEnablePart;
  removeDeviceConfig        = DefaultConfig.uris.removeDeviceConfig;
  resetDeviceUri            = DefaultConfig.uris.resetDevice;

  websocketUri = DefaultConfig.uris.deviceWebsocket;

  private deviceCache: Map<string, Observable<IPojoDevice>> = new Map();

  dataloader = new DataLoader<string, IPojoDevice | undefined>(keys => {
    const uri = DefaultConfig.uris.deviceMultiple;
    return lastValueFrom(this.httpClient.post<IPojoDevice[]>(uri, keys).pipe(map(devices => {
      const deviceMap = new Map<string, IPojoDevice>(devices.flatMap(device => ([[device._id!, device], [device.address, device]])));
      return keys.map(key => deviceMap.get(key));
    })));
  }, { cache: false, batchScheduleFn: (cb) => setTimeout(() => cb(), 100) });


  private subscriptions = new SubSink();

  constructor(
    private httpClient: HttpClient,
    private websocketConnection: WebsocketConnectionService,
  ) {
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getDevice(id: string, writable?: boolean, initial?: IPojoDevice): Observable<IPojoDevice> {
    const device$ = this.deviceCache.get(id);
    if (device$ !== undefined) {
      if (writable === true) {
        // return a copy
        return device$
          .pipe(switchMap(device => from(deepCopy(device))));
      }

      // return the original device, which is a deepFrozen object that cannot be changed
      return device$;
    }

    const oneTimeRequest$ = initial !== undefined ? of(initial) : from(this.dataloader.load(id))
      .pipe(switchMap(device => device ? of(device) : throwError(() => new Error('Device not found for ' + id))));

    const webSocketUpdates$ = this.subscribeUpdates(id).pipe(
      map(event => event.data as IPojoDevice),
    );

    const deviceAndUpdates$: Observable<IPojoDevice> = merge(oneTimeRequest$, webSocketUpdates$).pipe(
      finalize(() => this.deviceCache.delete(id)),
      delayedShareReplay(DefaultConfig.device.shareReplayDelaySeconds * 1000, 1),
      map(device => deepFreeze(device)),
    );

    this.deviceCache.set(id, deviceAndUpdates$);

    return deviceAndUpdates$.pipe(switchMap(device => from(deepCopy(device))));
  }

  getDevices(): Observable<IPojoDevice[]> {
    const deviceList$ = this.httpClient.get<IPojoDevice[]>(this.deviceListUrl);
    const deviceCreationFilter: IFilter = {
      filterType: 'DeviceCreationFilter'
    };
    const deviceCreationNotification$ = this.websocketConnection.subscribeWithFilter(this.websocketUri, deviceCreationFilter);

    return deviceCreationNotification$.pipe(
      startWith(undefined),
      switchMap(() => deviceList$),
      switchMap(devices => {
        const devices$ = devices.map(device => this.getDevice(device.address, true, device));

        if (devices$.length === 0) {
          return of([]);
        }
        return combineLatest(devices$);
      })
    );
  }

  getDevicesByTeam(teamID: string): Observable<IPojoDevice[]> {
    const deviceList$ = this.httpClient.get<IPojoDevice[]>(this.deviceListUrlByTeam.replace('{teamID}', teamID));
    const deviceCreationFilter: IFilter = {
      filterType: 'DeviceCreationFilter'
    };
    const deviceCreationNotification$ = this.websocketConnection.subscribeWithFilter(this.websocketUri, deviceCreationFilter);

    return deviceCreationNotification$.pipe(
      startWith(undefined),
      switchMap(() => deviceList$),
      switchMap(devices => {
        const devices$ = devices.map(device => this.getDevice(device.address, true, device));

        if (devices$.length === 0) {
          return of([]);
        }
        return combineLatest(devices$);
      })
    );
  }

  postDevice(device: IPojoDevice) {
    return this.httpClient.post<IPojoDevice>(this.deviceUrl.replace('{deviceId}', encodeURIComponent(device.address.split('/').join('--'))), device);
  }

  createDevice(device: IPojoDevice) {
    return this.httpClient.post<IPojoDevice>(this.createDeviceUrl, device);
  }

  getDeviceTemplate(version: string): Observable<IDeviceTemplate> {
    return this.httpClient.get<IDeviceTemplate>(this.cocoTemplateUrl.replace('{version}', version));
  }

  getDeviceActions(deviceId: string): Observable<string[]> {
    return this.httpClient.get<string[]>(this.deviceActions.replace('{deviceId}', encodeURIComponent(deviceId.split('/').join('--'))));
  }

  executeDeviceAction(deviceId: string, action: string, parameters?: any) {
    return this.httpClient.post<any>(
      this.deviceActionExecution
        .replace('{deviceId}', encodeURIComponent(deviceId.split('/').join('--')))
        .replace('{action}', action),
      parameters ? { parameters } : null
    );
  }

  getDataInventory(address: string): Observable<IDataInventoryResponse> {
    return this.httpClient.get<IDataInventoryResponse>(
      this.dataInventoryUrl.replace('{deviceAddress}', encodeURIComponent(address.split('/').join('--')))
    );
  }

  forceUnblockDevice(deviceId: string, taskkey?: TaskKey) {
    if (taskkey === undefined) {
      return this.httpClient.post<any>(this.deviceForceUnblockAll.replace('{deviceId}', encodeURIComponent(deviceId.split('/').join('--'))), undefined);
    }
    return this.httpClient.post<any>(this.deviceForceUnblock.replace('{deviceId}', encodeURIComponent(deviceId.split('/').join('--')))
      .replace('{thingieId}', encodeURIComponent(taskkey.thingie))
      .replace('{processIdx}', taskkey.processIdx.toString())
      .replace('{taskIdx}', taskkey.taskIdx.toString()), undefined);
  }

  async getDeviceDataSeries(deviceId: string): Promise<DeviceSeries[]> {
    const device = (await lastValueFrom(this.getDevice(deviceId)
      .pipe(
        take(1)
      )))!;


    //// Select all device data series from shortcuts where the
    //// shortcut contains the packet type and property name information
    //// and has a representation within `packetContentDescription`

    const dataSeries: DeviceSeries[] = [];

    for (const key of Object.keys(device.shortcuts)) {
      const unparsedShortcut = device.shortcuts[key];
      const parts            = unparsedShortcut.split('.');
      if (parts.length !== 4) {
        continue;
      }

      const packetType = parts[1];
      const property   = parts[3];

      // Does the shortcut have a descriptor?...
      if (device.packetContentDescription[packetType] !== undefined) {
        // Does the descriptor contain a descriptor for the property?....
        if (device.packetContentDescription[packetType].items[property] !== undefined) {
          // Does the descriptor contain value description?

          if (device.packetContentDescription[packetType].items[property].valueInfo[0] !== undefined) {
            const packet: IPacketContentItem = {
              structureInfo: device.packetContentDescription[packetType].items[property].structureInfo,
              visibility: device.packetContentDescription[packetType].items[property].visibility,
              valueInfo: device.packetContentDescription[packetType].items[property].valueInfo
            };

            // Does the visibility of the packet content description
            if ( packet.visibility !== 'hidden') {
              const _deviceSeries: DeviceSeries = {
                ...createDefaultDeviceDataSeries(),
                identification: {
                  type: 'deviceSeries',
                  packetType,
                  property,
                  deviceAddress: device.address
                },
                dataItemDescription: {
                  [`${property}`]: packet
                }
              };

              if ('unit' in _deviceSeries.valueConfig && _deviceSeries.valueConfig.unit === '' && packet.valueInfo[0].unit !== '') {
                _deviceSeries.valueConfig.unit = packet.valueInfo[0].unit;
              }

              dataSeries.push(_deviceSeries);
            }//packet content description
          } // value description
        } // property descriptor
      } // shortcut descriptor
    } // for...in

    return dataSeries;
  }

  async deleteConfig(deviceAddress: string, handleId: string): Promise<IPojoDevice> {
    if (deviceAddress === undefined) {
      throw new Error('Cannot delete from device if no device address is selected');
    }

    const path = this.removeDeviceConfig;
    return (await lastValueFrom(this.httpClient.delete<IPojoDevice>(path
      .replace('{deviceAddress}', encodeURIComponent(deviceAddress.split('/').join('--')))
      .replace('{handleID}', handleId))))!;
  }

  hashCache = new LRUMap<IDeviceResourceCheck, string>(500);
  checkResourcesDataloader = new DataLoader((devices) => lastValueFrom(this.httpClient.post<{ result: IDeviceResourceCheck[]; errors: { [key: number]: string } }>(
    this.calculateResourceUsageUrl,
    devices
  ))
    .then(data => {
      // clear cache after request - we use the cache only to deduplicate requests, but not to store old information.
      this.checkResourcesDataloader.clearAll();
      return data.result.map((device, i) => device ?? new Error(data.errors[i]));
    }), {
    cacheKeyFn: (device: IDeviceResourceCheck) => this.hashCache.find(device) ?? hash(device),
    batchScheduleFn: (cb) => setTimeout(() => cb(), 100)
  });

  async checkResources(device: IDeviceResourceCheck): Promise<IDeviceResourceCheck | Error>;
  async checkResources(deviceList: IDeviceResourceCheck[]): Promise<(IDeviceResourceCheck | Error)[]>;
  async checkResources(deviceOrList: IDeviceResourceCheck | IDeviceResourceCheck[]): Promise<(IDeviceResourceCheck | Error) | (IDeviceResourceCheck | Error)[]> {
    if (Array.isArray(deviceOrList)) {
      return await this.checkResourcesDataloader.loadMany(deviceOrList);
    } else {
      return await this.checkResourcesDataloader.load(deviceOrList);
    }
  }

  subscribeUpdates(deviceId?: string): Observable<IDataEvent<IPojoDevice>> {
    let requestFilter: IDeviceFilter;
    if (deviceId) {
      if (deviceId.includes('://')) {
        // this is an address
        requestFilter = {
          filterType: 'DeviceFilter',
          address: deviceId
        };
      } else {
        // it is an objectid
        requestFilter = {
          filterType: 'DeviceFilter',
          _id: deviceId
        };
      }
    } else {
      // a match-all filter
      requestFilter = {
        filterType: 'DeviceFilter'
      };
    }

    return this.websocketConnection.subscribeWithFilter<IPojoDevice>(this.websocketUri, requestFilter)
      .pipe(bufferWhileTabIsHidden());
  }

  /**
   * Disable device
   *
   * @param device - The device's id or address
   * @returns The device object after modification
   */
  async disableDevice(opts: { address: string }) {
    return (await lastValueFrom(this.httpClient.post<IPojoDevice>(
      this.disableDeviceUri.replace(
        '{address}',
        encodeURIComponent(opts.address.split('/').join('--'))
      ),
      undefined
    )))!;
  }

  /**
   *
   * @param device - The device's id or address
   * @returns The device object after modification
   */
  async enableDevice(opts: { address: string }) {
    return (await lastValueFrom(this.httpClient.post<IPojoDevice>(
      this.enableDeviceUri.replace(
        '{address}',
        encodeURIComponent(opts.address.split('/').join('--'))
      ),
      undefined
    )))!;
  }

  async uploadConfig(res: IDevice): Promise<IPojoDevice> {
    if (res.deviceAddress === undefined) {
      throw new Error('No device assigned');
    }
    let dev = (await lastValueFrom(this.getDevice(res.deviceAddress, true).pipe(take(1))))!;
    addConfigItemFromInputToDevice(dev, res);
    dev = (await lastValueFrom(this.postDevice(dev)))!;
    return dev;
  }


  resetDevice(deviceId: string): Promise<void> {
    return lastValueFrom(this.httpClient.post<void>(
      this.resetDeviceUri.replace(
        '{deviceId}',
        encodeURIComponent(deviceId.split('/').join('--'))
      ), {}
    ));
  }

  archiveDevice(deviceId: string): Promise<void> {
    return lastValueFrom(this.httpClient.post<void>(
      DefaultConfig.uris.deviceArchive.replace('{deviceId}', deviceId), {}
    ));
  }

  unarchiveDevice(deviceId: string): Promise<void> {
    return lastValueFrom(this.httpClient.delete<void>(
      DefaultConfig.uris.deviceArchive.replace('{deviceId}', deviceId), {}
    ));
  }

  getShakerFeatures(): Promise<ShakerFeatureDescriptor[]> {
    return lastValueFrom(this.httpClient.get<ShakerFeatureDescriptor[]>(
      DefaultConfig.uris.deviceShakerFeatureList
    ));
  }

  addModbusTcpConnection(data: IModbusTcpConnectionDTO): Promise<void> {
    return lastValueFrom(this.httpClient.post<void>(
      DefaultConfig.device.addModbusTcpConnection,
      data
    ));
  }

  removeModbusTcpConnection(data: IModbusTcpConnectionDTO): Promise<void> {
    return lastValueFrom(this.httpClient.delete<void>(
      DefaultConfig.device.removeModbusTcpConnection,
      { body: data }
    ));
  }

  updateModbusTcpConnection(data: { address: string; dto: IModbusTcpConnectionDTO }): Promise<void> {
    const uri = DefaultConfig.device.updateModbusTcpConnection.replace('{deviceAddress}', encodeURIComponent(data.address.split('/').join('--')));

    return lastValueFrom(this.httpClient.post<void>(
      uri,
      data.dto
    ));
  }
}
