<div>
  <div class="mb-2 flex flex-nowrap items-center">
    <label class="mr-2 typo-caption text-gray-500 font-normal">Latest data</label>
  </div>

  <ng-container *ngIf="_deviceInfo$ | async as deviceInfo; else loadingDevice">

    <ng-container 
      [ngTemplateOutlet]="mode === 'view' ? viewMode : controlMode"
      [ngTemplateOutletContext]="{ $implicit: deviceInfo }" />

  </ng-container>


  <ng-template #loadingDevice>
    <div class="grid grid-cols-4">
      <div class="col-span-4">
        <ion-skeleton-text animated />
      </div>
      <div class="col-span-4">
        <ion-skeleton-text animated />
      </div>
      <div class="col-span-4">
        <ion-skeleton-text animated />
      </div>
    </div>
  </ng-template>
    
</div>


<ng-template #viewMode let-deviceInfo>
  <div class="grid grid-cols-[1fr_auto_auto_auto_auto_auto] gap-x-6 gap-y-1">
    <!-- Header row -->
    <b class="self-end">Parameter</b>
    <b class="self-end">Reading</b>
    <b class="self-end">Control</b>
    <b class="self-end text-right" matTooltip="Setpoint at machine (click for more information)">
      <a href="#" (click)="$event.preventDefault(); _explainSetpointTypes()">Machine setpoint</a>
    </b>
    <b class="align-bottom text-right" matTooltip="Setpoint in software (click for more information)">
      <a href="#" (click)="$event.preventDefault(); _explainSetpointTypes()">Software setpoint</a>
    </b>
    <button 
      mat-icon-button
      (click)="_onSwitchToControlMode()"
      matTooltip="Change setpoints"
      class="self-end text-left !-ml-6"
    >
      <ion-icon name="pencil-outline" />
    </button>

    <!-- Feature values (without door) -->
    <ng-container *ngFor="let feature of deviceInfo.features; trackBy: _trackByFn">
      <span class="whitespace-nowrap">{{ feature.description }}</span>
      <span class="justify-self-end">{{ feature.value }}</span>
      <span class="justify-self-end">{{ feature.enabled ?  'on' : 'off' }}</span>
      <span class="justify-self-end">{{ feature.reportedSetpoint }}</span>
      <span class="justify-self-end">{{ feature.configuredSetpoint === -1 ? 'not set' : feature.configuredSetpoint }}</span>
      <span></span>
    </ng-container>

    <!-- Row for door information -->
    <span *ngIf="deviceInfo.doorOpen !== undefined">Door</span>
    <span class="justify-self-end">{{ deviceInfo.doorOpen ? 'open' : 'closed' }}</span>
    <span class="justify-self-end">-</span>
    <span class="justify-self-end">-</span>
    <span class="justify-self-end">-</span>
    <span></span>
  </div>  
</ng-template>


<ng-template #controlMode let-deviceInfo>
  <div class="grid grid-cols-[1fr_auto_auto_auto_auto_auto] gap-x-6 gap-y-1">
    <!-- Header row -->
    <b class="self-end">Parameter</b>
    <b class="self-end">Reading</b>
    <b class="self-end">Control</b>
    <b class="self-end text-right" matTooltip="Setpoint at machine (click for more information)">
      <a href="#" (click)="$event.preventDefault(); _explainSetpointTypes()">Machine setpoint</a>
    </b>
    <b class="self-end text-right" matTooltip="Setpoint in software (click for more information)">
      <a href="#" (click)="$event.preventDefault(); _explainSetpointTypes()">Software setpoint</a>
    </b>
    <button 
      mat-icon-button
      (click)="mode='view'"
      matTooltip="View data"
      class="self-end text-left !-ml-6"
    >
      <ion-icon name="eye-outline" />
    </button>

    <!-- Feature values (without door) -->
    <ng-container *ngFor="let feature of deviceInfo.features; trackBy: _trackByFn">
      <span class="whitespace-nowrap">{{ feature.description }}</span>
      <span class="justify-self-end">{{ feature.value }}</span>
      <span class="justify-self-end">
        {{ feature.enabled ?  'on' : 'off' }}
        <!-- Currently changing the enabled-state via tcp is not possible/implemented -->
        <!-- <mat-slide-toggle
          [checked]="feature.enabled"
          (change)="_onChangeFeatureState(feature, $event.checked)"/> -->
      </span>
      <span class="justify-self-end">{{ feature.reportedSetpoint}}</span>
      <span class="justify-self-end">
        <input
          class="w-[10ch]"
          type="number"
          [ngModel]="_userSetpoints[feature.parameterKey].newSetpoint"
          (ngModelChange)="_onChangeSetpoint(feature, $any($event))" />
      </span>
      <span></span>
    </ng-container>

    <!-- Confirmation button -->
    <span class="
      col-span-5
      flex justify-end items-center gap-2
      border-solid border-0 border-t border-gray-200
      mt-2 pt-2
    ">
      <ion-icon name="information-circle-outline" />
      <small class="mr-auto">It might take a few moments for new setpoints to update</small>

      <button 
        mat-stroked-button
        (click)="mode='view'"
      >
        Cancel
      </button>

      <button 
        mat-flat-button
        color="primary"
        (click)="_onConfirmNewSetpoints()"
        [disabled]="_hasInvalidSetpoints"
      >
        Confirm
      </button>
    </span>
  </div>  
</ng-template>


