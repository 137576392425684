import { Component, Inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { isEqual } from 'lodash';
import { combineLatest, lastValueFrom, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { IPojoDevice } from 'src/app/model/device/IPojoDevice';
import { IThingie } from 'src/app/model/thingie/thingie';
import { ITeam } from 'src/app/model/user-management/team';
import { DeviceService } from 'src/app/services/device/device.service';
import { LogService } from 'src/app/services/loggers/logger.service';
import { TeamService } from 'src/app/services/team/team.service';
import { ThingieService } from 'src/app/services/thingie/thingie-service.service';
import {
  ConfirmationDialogOptions,
  ConfirmationResults,
  UINotificationService
} from 'src/app/services/ui-notification/uinotification.service';
import { UserService } from 'src/app/services/user/user.service';
import { deepCopySync } from 'src/app/utility/deep-copy';
import { resolveShortcut } from 'src/app/utility/device-shortcut';
import { extractConfigurationFromKuhnerShaker } from 'src/app/utility/device/shaker/extract-configuration-from-kuhner-device';
import { SubSink } from 'subsink';


/**
 * team information passed to the template
 */
interface ITeamInfo {
  id: string;
  name: string;
}


interface IActionInfo {
  key: string;
  displayText: string;
}


@Component({
  templateUrl: './device-detail-dialog.component.html',
  styleUrls: ['./device-detail-dialog.component.scss']
})
export class DeviceDetailDialogComponent implements OnDestroy, OnInit {
  deviceObj?: IPojoDevice;
  connectedCoordinatorPanID?: number;
  connectedCoordinatorChannel?: number;
  private subscriptions = new SubSink();
  _deviceActions: IActionInfo[] = [];
  private teamList: ITeam[] = [];
  _teamInfoList: ITeamInfo[] = [];
  private thingieList: IThingie[] = [];
  isEditingName = false;
  isEditingPanID = false;
  showBlockedPopout = false;
  connectionState = 'unknown';
  newNameFormControl = new FormControl('');
  newPanIDFormControl = new FormControl('');
  showMenu = false;

  /**
   * The action to be executed on button press.
   *
   * @see {@link deviceActionMap}
   */
  private selectedAction = 'Actions';

  /**
   * The text to be displayed for the action.
   *
   * @see {@link deviceActionMap}
   */
  selectedActionDisplayText = 'Actions';

  /**
   * Maps action key to action button label
   */
  private deviceActionMap = new Map([
    ['START_BLINK', 'Start blink'],
    ['STOP_BLINK', 'Stop blink'],
    ['BLINK_30_S', 'Blink for 30s'],
    ['SOFTRESET', 'Soft reset'],
    ['HARDRESET', 'Hard reset'],
    ['AUTORESET', 'Auto reset'],
    ['BOOT', 'Boot'],
    ['RESET', 'Reset'],
    ['Connect', 'Connect'],
    ['Disconnect', 'Disconnect'],
  ]);

  isDeviceArchived = false;
  isAdmin?: boolean;


  /**
   * Shaker-specific connection information.
   *
   * @private
   */
  _shakerConnectionInfo?: { ip: string; port: number };


  /**
   * @private
   */
  _newShakerConnectionInfo?: { ip: string | null; port: number };


  /**
   * Switches between edit- and readonly-mode of the shaker connection info.
   *
   * @private
   */
  _editingShakerConnectionInfo = false;

  private thingieIds$ = new Subject<string[]>();

  constructor(
    private deviceService: DeviceService,
    @Inject(MAT_DIALOG_DATA) private deviceId: string,
    private uiNotifications: UINotificationService,
    private teamService: TeamService,
    private router: Router,
    private thingieService: ThingieService,
    private user: UserService,
    private log: LogService
  ) {
  }

  ngOnInit() {
    const device$ = this.loadDeviceDetail(this.deviceId).pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
      tap(() => {
        const blockedByThingies = this.deviceObj?.blockedBy.map(tk => tk.thingie);
        if (blockedByThingies) {
          this.thingieIds$.next(blockedByThingies);
        }
      })
    );

    // combine the latest emitted thingie IDs and fetch the thingies
    this.subscriptions.sink = this.thingieIds$.pipe(
      distinctUntilChanged((a, b) => isEqual(a, b)), // ensure IDs have actually changed before re-fetching
      switchMap(ids => this.thingieService.getThingiesByIds(ids)), // fetch thingies only when ids change
      tap((thingies) => {
        this.thingieList = thingies;
      })
    ).subscribe();

    this.subscriptions.sink = combineLatest([device$, this.getTeamListAndUserRole()])
      .subscribe(() => {
        // Resolve list of teams to be displayed
        this._teamInfoList = this.deviceObj!.teams.map(teamId => {
          const team = this.teamList.find(t => t._id === teamId);
          return {
            id: teamId,
            name: team ? team.name : 'unknown'
          };
        });
      });


    this.loadDeviceActions(this.deviceId);
  }

  /**
   * @private
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private async getTeamListAndUserRole() {
    const _currentUser = await this.user.getCurrentUser();
    this.isAdmin = _currentUser.admin;
    this.teamList = await this.teamService.getTeamList();
  }

  getSignalStrengthQuality() {
    if (this.deviceObj?.info?.InternalState?.values?.connectionStatus === 'CONNECTED') {
      const signalStrength = this.deviceObj?.info?.StatusAnswer?.values?.signalStrength as number;
      if (signalStrength > 100) {
        return 'Excellent';
      } else if (signalStrength > 50 && signalStrength <= 100) {
        return 'Good';
      } else if (signalStrength > 10 && signalStrength <= 50) {
        return 'Ok';
      } else if (signalStrength > 0 && signalStrength <= 10) {
        return 'Bad';
      } else {
        return 'No signal';
      }
    } else {
      return 'unknown';
    }
  }

  getBatteryPower() {
    const batteryPercent = this.deviceObj?.info?.StatusAnswer?.values?.batteryPercentage as number;
    if (batteryPercent > 50 && batteryPercent <= 100) {
      return 'Full';
    } else if (batteryPercent > 20 && batteryPercent <= 50) {
      return 'Half';
    } else if (batteryPercent >= 0 && batteryPercent <= 20) {
      return 'Low';
    } else {
      return '';
    }
  }

  async getCGQBioRConnectionState() {
    const portNum = this.deviceObj?.info?.InternalState?.values?.portNumber as number;
    const hubAddress = this.deviceObj?.info?.InternalState?.values?.hubAddress as string;
    let hubName: string;
    try {
      hubName = ((await lastValueFrom(this.deviceService.getDevice(hubAddress).pipe(take(1))))!).name;
    } catch {
      hubName = 'unknown';
    }
    this.connectionState = 'Port ' + portNum.toString() + ' of ' + hubName;
    return this.connectionState;
  }

  async updateConnectionState(): Promise<void> {
    if (!this.deviceObj) {
      this.connectionState = 'unknown';
      return;
    }

    if (this.deviceObj.info!.InternalState!.values!.connectionStatus === 'CONNECTED') {
      const inputType = this.deviceObj.deviceInputTypes?.[0];
      switch (inputType) {
        case 'AquilaCGQSensorPlate':
        case 'AquilaBioRSensorPlate':
          this.connectionState = await this.getCGQBioRConnectionState();
          break;

        case 'AquilaCGQBaseStation':
          this.connectionState = 'USB connected';
          break;

        default:
          this.connectionState = 'Connected';
      }
    } else {
      this.connectionState = 'Not connected';
    }
  }

  showBlockedByPopout() {
    this.showBlockedPopout = true;
  }

  toggleNameEdit() {
    this.isEditingName = true;
  }

  togglePanIDEdit() {
    this.isEditingPanID = true;
  }

  async savePanID(newPanID: string | null) {
    if (newPanID !== null && isNaN(newPanID as any as number) === false) {
      if (this.deviceObj?.configuration['1'].values) {
        this.deviceObj.configuration['1'].values.panID = Number(newPanID);
        try {
          (await lastValueFrom(this.deviceService.postDevice(this.deviceObj)))!;
        } catch (e: unknown) {
          this.uiNotifications.displayErrorAlert({
            title: 'Error',
            message: 'PanID could not be changed.',
            details: e instanceof Error ? e.message : undefined
          }, e);
        } finally {
          this.isEditingPanID = false;
        }
      } else {
        this.isEditingPanID = false;
      }
    } else {
      this.isEditingPanID = false;
    }
  }


  async saveNameEdit(newName: string | null) {
    if (newName !== null && newName !== '') {
      this.deviceObj!.name = newName;
      try {
        (await lastValueFrom(this.deviceService.postDevice(this.deviceObj!)))!;
      } catch (e: unknown) {
        this.uiNotifications.displayErrorAlert({
          title: 'Error',
          message: 'Device name could not be changed.',
          details: e instanceof Error ? e.message : undefined
        }, e);
      } finally {
        this.isEditingName = false;
      }
    } else {
      this.isEditingName = false;
    }
  }

  private getLISDrivePanID() {
    if (this.deviceObj?.deviceInputTypes?.[0] === 'AquilaLisDrive'
      && this.deviceObj?.info?.InternalState?.values?.connectionStatus === 'CONNECTED') {
      const coordinatorID = this.deviceObj?.info?.InternalState?.values?.connectedCoordinator as string;
      this.subscriptions.sink = this.deviceService.getDevice(coordinatorID).subscribe(coordinator => {
        this.connectedCoordinatorPanID = coordinator.configuration['1'].values?.panID as number;
      });
    }
  }

  private getLISDriveChannel() {
    if (this.deviceObj?.deviceInputTypes?.[0] === 'AquilaLisDrive'
      && this.deviceObj?.info?.InternalState?.values?.connectionStatus === 'CONNECTED') {
      const coordinatorID = this.deviceObj?.info?.InternalState?.values?.connectedCoordinator as string;
      this.subscriptions.sink = this.deviceService.getDevice(coordinatorID).subscribe(coordinator => {
        this.connectedCoordinatorChannel = coordinator.configuration['2'].values?.channel as number;
      });
    }
  }

  containsTrue(arr: unknown) {
    if (Array.isArray(arr)) {
      return (arr as unknown[]).includes(true);
    } else {
      return false;
    }
  }

  /**
   * Select the action to be executed on the device.
   *
   * Note: does not execute the action, just picks it for when the user
   *       presses the button.
   *
   * @param action
   */
  selectAction(action: string) {
    const text = this.translateDeviceAction(action);
    if (text === undefined) {
      // Don't just throw, but allow the action to be tested by the user.
      this.log.error('DeviceDetailDialogComponent', 'setSelectedAction', 'Action not found');
      this.selectedActionDisplayText = 'Unknown';
    } else {
      this.selectedActionDisplayText = text;
    }

    this.selectedAction = action;
  }

  getThingieName(thingieId: string | undefined) {
    if (!thingieId) {
      return;
    }
    return this.thingieList.find(thingie => thingie._id === thingieId)?.name;
  }

  /**
   * For a given device ID, resolves the device object and stores it in the component.
   *
   * @param deviceId ID of device to resolve
   * @returns Observable with the device object (this will automatically be stored in
   *         the component's `deviceObj` field)
   */
  private loadDeviceDetail(deviceId: string): Observable<IPojoDevice> {
    return this.deviceService.getDevice(deviceId, true).pipe(tap(device => {
      this.deviceObj = device;
      this.isDeviceArchived = device.hidden;
      this.setShakerConnectionInfo();
      this.getLISDrivePanID();
      this.getLISDriveChannel();
      void this.updateConnectionState();
    }));
  }


  private translateDeviceAction(deviceAction: string) {
    return this.deviceActionMap.get(deviceAction);
  }

  private getDeviceName(): string {
    if (!this.deviceObj) {
      return 'Device Details';
    }

    return `Device: ${this.deviceObj.name}`;
  }

  private loadDeviceActions(deviceId: string) {
    this.subscriptions.sink = this.deviceService
      .getDeviceActions(deviceId)
      .subscribe(deviceActions => {
        this._deviceActions = deviceActions.map(action => ({
          key: action,
          displayText: this.translateDeviceAction(action) || 'Unknown'
        }));
      });
  }

  /**
   * Executes the selected action on the device.
   *
   * @private
   */
  async executeSelectedAction() {
    const action     = this.selectedAction;
    const actionText = this.selectedActionDisplayText;
    const deviceName = this.getDeviceName();

    if (action !== 'Actions') {
      const answer = await this.uiNotifications.askForConfirmation(
        `Are you sure you want to execute action '${actionText}' on device ${deviceName}?`
      );

      if (answer !== ConfirmationResults.Yes) {
        return;
      }

      try {
        (await lastValueFrom(this.deviceService.executeDeviceAction(this.deviceId, action)))!;
        this.uiNotifications.displaySnackbar({
          message: 'Action execution successful',
        });
      } catch (e: unknown) {
        this.uiNotifications.displayErrorAlert({
          title: 'Error',
          message: 'Action execution failed',
          details: e instanceof Error ? e.message : undefined
        }, e);
      }
    }
  }


  async onResetStatus() {
    const resetConfirmationDialog: ConfirmationDialogOptions = {
      message: 'Are you sure you want to <b>reset  the device:' + `${this.getDeviceName()}?</b>`,
      title: `Reset Device ${this.getDeviceName()}`,
      approvalButton: {
        text: 'Reset',
        color: 'warn',
      },
      rejectionButton: {
        text: 'Cancel',
      },
    };
    const answer = await this.uiNotifications.askForConfirmation(
      resetConfirmationDialog
    );

    if (answer !== ConfirmationResults.Yes) {
      return;
    }

    await this.deviceService.resetDevice(this.deviceObj!._id!);
  }

  showTeamDetail(team: string) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/team-detail/general/', team])
    );

    window.open(url, '_blank');
  }

  showThingieDetail(thingieId: string | undefined) {
    if (!thingieId) {
      return;
    }
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/thingies/thingie/', thingieId])
    );

    window.open(url, '_blank');
  }

  showDeviceDetail(deviceId: string | undefined) {
    if (!deviceId) {
      return;
    }
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/devices-debug/device/', deviceId])
    );

    window.open(url, '_blank');
  }

  async toggleDevicesArchived() {
    // toggle
    const isDeviceToggle = !this.isDeviceArchived;
    if (isDeviceToggle === true) {
      // If shaker, disable connection
      await this.disableShakerConnection();

      // hide device
      await this.deviceService.archiveDevice(this.deviceId);
      this.uiNotifications.displaySnackbar({
        message: 'Device was Archived'
      });
    } else {
      // show device
      await this.deviceService.unarchiveDevice(this.deviceId);

      // If shaker, re-enable connection
      await this.enableShaker();

      this.uiNotifications.displaySnackbar({
        message: 'Device was Restored'
      });
    }

    this.isDeviceArchived = isDeviceToggle;
  }

  isDisableButtonFromConnectionState(device: IPojoDevice | undefined) {
    if (device !== undefined) {
      const status = resolveShortcut(device, 'connectionStatus', 'DISCONNECTED');
      if (status !== 'CONNECTED') {
        return true;
      }
    }
    return false;
  }


  /**
   * If the device object is set and a shaker, this method
   * will set the shaker connection information.
   */
  private setShakerConnectionInfo() {
    if (!this.deviceObj) {
      throw new Error('setShakerConnectionInfo needs to be called after the device object is set');
    }

    if (this.deviceObj.devType !== 'KuhnerShaker') {
      return;
    }

    const config = this.extractTcpConfigurationFromShakerObject(this.deviceObj);
    if (config) {
      this._shakerConnectionInfo = {
        ip: config.values?.ipAddress as string ?? 'unknown',
        port: config.values?.port as number ?? 'unknown'
      };
    }
  }


  /**
   * @private
   */
  _enableShakerConnectionInfoEdit() {
    this._newShakerConnectionInfo = deepCopySync(this._shakerConnectionInfo);

    this._editingShakerConnectionInfo = true;
  }


  /**
   * @private
   */
  _discardShakerConnectionInfoEdit() {
    this._newShakerConnectionInfo = undefined;
    this._editingShakerConnectionInfo = false;
  }


  /**
   * @private
   */
  async _applyShakerConnectionInfoEdit() {
    if (!this.deviceObj || !this._newShakerConnectionInfo) {
      throw new Error('Device object or new shaker connection info is not set');
    }

    if (isEqual(this._newShakerConnectionInfo, this._shakerConnectionInfo)) {
      this._editingShakerConnectionInfo = false;
      return;
    }

    try {
      await this.deviceService.updateModbusTcpConnection({
        address: this.deviceObj!.address,
        dto: {
          ipAddress: this._newShakerConnectionInfo!.ip!,
          port: this._newShakerConnectionInfo!.port,
          deviceType: 'KuhnerShaker'
        }
      });

      this._editingShakerConnectionInfo = false;
    } catch (e) {
      this.log.error('DeviceDetailDialogComponent', '_applyShakerConnectionInfoEdit', 'Could not update shaker connection information', e);
      this.uiNotifications.displayErrorAlert({
        title: 'Error',
        message: 'Could not update shaker IP and port',
        details: e instanceof Error ? e.message : undefined
      }, e);
    }
  }


  private async disableShakerConnection() {
    if (this.deviceObj?.devType !== 'KuhnerShaker') {
      return;
    }

    try {
      const { values } = this.extractTcpConfigurationFromShakerObject(this.deviceObj);
      if (!values) {
        throw new Error('Could not extract TCP configuration from shaker object');
      }

      await this.deviceService.removeModbusTcpConnection({
        deviceType: 'KuhnerShaker',
        ipAddress: values.ipAddress as string,
        port: values.port as number
      });
    } catch (e) {
      this.log.error('DeviceDetailDialogComponent', 'disableShaker', 'Could not disable shaker connection', e);
      this.uiNotifications.displayErrorAlert({
        title: 'Error',
        message: 'Could not disable shaker connection',
        details: e instanceof Error ? e.message : undefined
      }, e);
    }
  }


  private async enableShaker() {
    if (this.deviceObj?.devType !== 'KuhnerShaker') {
      return;
    }

    try {
      const { values } = this.extractTcpConfigurationFromShakerObject(this.deviceObj);
      if (!values) {
        throw new Error('Could not extract TCP configuration from shaker object');
      }

      await this.deviceService.addModbusTcpConnection({
        deviceType: 'KuhnerShaker',
        ipAddress: values.ipAddress as string,
        port: values.port as number
      });
    } catch (e) {
      this.log.error('DeviceDetailDialogComponent', 'enableShaker', 'Could not enable shaker connection', e);
      this.uiNotifications.displayErrorAlert({
        title: 'Error',
        message: 'Could not enable shaker connection',
        details: e instanceof Error ? e.message : undefined
      }, e);
    }
  }


  private extractTcpConfigurationFromShakerObject(shakerObj: IPojoDevice) {
    return extractConfigurationFromKuhnerShaker(shakerObj, 'TcpConnection');
  }
}


@Injectable({
  providedIn: 'root'
})
export class DeviceDetailDialogService {
  constructor(private dialogSvc: MatDialog) { }

  open(deviceId: string) {
    return this.dialogSvc.open<
      DeviceDetailDialogComponent,
      string,
      null
    >(
      DeviceDetailDialogComponent,
      {
        data: deviceId,
        minWidth: '800px'
      }
    );
  }
}
