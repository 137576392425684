import { Injectable } from '@angular/core';
import { Observer, PartialObserver, Subscription } from 'rxjs';
import { IEvent } from 'src/app/model/events/base-event';
import { EventServiceImpl } from './implementation/event.service';

type ClassType<T> = new (...args: any[]) => T;

@Injectable({
  providedIn: 'root',
  useClass: EventServiceImpl
})
export abstract class EventService {
  abstract subscribe<T extends IEvent>(
    eventType: ClassType<T>,
    callback: Observer<T> | PartialObserver<T> | ((ev: T) => void)
  ): Subscription;

  abstract broadcast<T extends IEvent>(event: T): void;
}

export class ApplicationTitleUpdatedEvent implements IEvent {
  // currently the newTitle parameter is not actively used
  // its there because we might want it for future use :)
  constructor(public newTitle?: string) { } type = 'ApplicationTitleUpdatedEvent';
}
