import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import DataLoader from 'dataloader';
import * as FileSaver from 'file-saver';
import { Observable, Subject, Subscription, combineLatest, from, lastValueFrom, merge, of, throwError } from 'rxjs';
import { debounceTime, finalize, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { IDialogResult as DataExportDTO } from 'src/app/components/dialogs/object-export-dialog/object-export-dialog.component';
import { AnnotationSeries } from 'src/app/model/annotation-series';
import { IDataSeries } from 'src/app/model/data-series';
import { IAnnotation, IAnnotationAttachment } from 'src/app/model/thingie/annotation';
import { ITask } from 'src/app/model/thingie/task';
import { IProcess, IThingie, IThingieIdName } from 'src/app/model/thingie/thingie';
import { deepCopy, deepCopySync } from 'src/app/utility/deep-copy';
import { deepFreeze } from 'src/app/utility/deep-freeze';
import {
  createDefaultAnnotationDataSeries,
  createDefaultArrayValuesConfiguration,
  createDefaultThingieSeries
} from 'src/app/utility/default-object-creator';
import { bufferWhileTabIsHidden, delayedShareReplay } from 'src/app/utility/rxjs';
import { searchBytes } from 'src/app/utility/string-search';
import { DefaultConfig } from 'src/assets/default.config';
import { SubSink } from 'subsink';
import { IDataEvent, IThingieFilter, WebsocketConnectionService } from '../../common/websocket-connection.service';
import { LogService } from '../../loggers/logger.service';
import { IAttachmentLocation, ICreateAnnotationsResult, ISetTargetStateEntry, IThingieMostRecentPagination, ThingieService } from '../thingie-service.service';

@Injectable({
  providedIn: 'root'
})
export class ThingieServiceImpl implements ThingieService, OnDestroy {
  private mostRecentThingiesPagination: IThingieMostRecentPagination;
  private subscriptions = new SubSink();
  private websocketUri = DefaultConfig.uris.dataWebsocket;
  private thingieCache: Map<string, Observable<IThingie>> = new Map();
  private thingieRefreshRequest$ = new Subject<void>();
  private newThingieNotification$ = this.websocketConnection.subscribeWithFilter(this.websocketUri, {
    filterType: 'ThingieCreationFilter'
  }).pipe(debounceTime(500));
  private thingieRefresh$ = merge(
    this.thingieRefreshRequest$,
    this.newThingieNotification$
  ).pipe(
    startWith(undefined),
    switchMap(() => this.httpClient.get<IThingie[]>(DefaultConfig.uris.thingieList)),
    shareReplay({ refCount: true, bufferSize: 1 })
  );
  private thingieObservableList$ = this.thingieRefresh$.pipe(
    map(thingies => thingies.map(thingie => this.getThingieById(thingie._id!, true, thingie)))
  );
  private thingieList$ = this.thingieObservableList$.pipe(
    switchMap(thingies$ => thingies$.length > 0 ? combineLatest(thingies$) : of([]))
  );

  // check for thingie state change
  private thingieStateNotification$ = this.websocketConnection.subscribeWithFilter<{ state: string; thingieId: string }>(this.websocketUri, {
    filterType: 'ThingieStateFilter'
  });
  private thingieNewState$ =
    this.thingieStateNotification$.pipe(
      map((event) => event.data),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

  dataloader = new DataLoader<string, IThingie | undefined>(keys => {
    const uri = DefaultConfig.uris.thingiesByIds;
    return lastValueFrom(this.httpClient.post<IThingie[]>(uri, keys).pipe(map(thingies => {
      const thingieMap = new Map<string, IThingie>(thingies.map(thingie => ([thingie._id!, thingie])));
      return keys.map(key => thingieMap.get(key));
    })));
  }, { cache: false, batchScheduleFn: (cb) => setTimeout(() => cb(), 100) });


  private lastRecentThingieUrl = '';
  private recentThingieList$$!: Observable<Observable<IThingie>[]>;
  private recentThingieList$!: Observable<IThingie[]>;
  private tagsList$!: Observable<string[]>;

  constructor(
    private httpClient: HttpClient,
    private log: LogService,
    private websocketConnection: WebsocketConnectionService,
  ) {
    this.mostRecentThingiesPagination = {
      result: [],
      pageSize: 0,
      totalSize: 0,
      collectionSize: 0,
      currentPage: 1,
    };
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  refresh(): Observable<void> {
    this.log.debug('Explicit thingie refresh requested');
    this.thingieRefreshRequest$.next();
    return of(undefined);
  }

  setTaskTargetState(thingieId: string, processIdx: number, taskIdx: number, state: string): Promise<void> {
    const uri = DefaultConfig.uris.thingieTask
      .replace('{thingieId}', thingieId)
      .replace('{processIdx}', processIdx.toString())
      .replace('{taskIdx}', taskIdx.toString())
      .replace('{action}', `targetState/${state}`);
    return lastValueFrom(this.httpClient.post<void>(uri, {}));
  }

  getThingieList(): Observable<IThingie[]> {
    return this.thingieList$.pipe(
      map(list => deepCopySync(list))
    );
  }

  getThingiesByIds(ids: string[]): Observable<IThingie[]> {
    const uri = DefaultConfig.uris.thingiesByIds;
    return this.httpClient.post<IThingie[]>(uri, ids);
  }

  getThingieNameInfoRequest(ids?: string[]): Observable<IThingieIdName[]> {
    const uri = DefaultConfig.uris.thingieNameInfoRequest;

    if (!ids || ids.length === 0) {
      ids = [];
    }

    return this.httpClient.post<IThingieIdName[]>(uri, ids);
  }

  getThingieNewState(): Observable<{ state: string; thingieId: string }> {
    return this.thingieNewState$;
  }

  getThingieObservables(): Observable<Observable<IThingie>[]> {
    return this.thingieObservableList$;
  }

  getRecentThingieList$$(page: number, pageSize: number, filters: {
    [key: string]: any;
  }): Observable<Observable<IThingie>[]> {
    this.recentThingieList$$ = this.newThingieNotification$.pipe(
      startWith(undefined),
      // re-fetch recent thingies
      switchMap(() => {
        this.lastRecentThingieUrl = DefaultConfig.uris.recentThingieList + '?';

        if (pageSize > 0) {
          this.lastRecentThingieUrl += `&limit=${pageSize}`;
          this.lastRecentThingieUrl += `&page=${page}`;
        }

        for (const key of Object.keys(filters)) {
          if (Object.prototype.hasOwnProperty.call(filters, key)) {
            const element = filters[key];

            this.lastRecentThingieUrl += `&${key}=${element}`;
          }
        }

        return this.httpClient.get<IThingieMostRecentPagination>(this.lastRecentThingieUrl);
      }),
      tap((res) => {
        this.mostRecentThingiesPagination = res;
      }),
      map((response: IThingieMostRecentPagination) => response.result.map(thingie => this.getThingieById(thingie._id!, true, thingie)))
    );

    this.recentThingieList$ = this.recentThingieList$$.pipe(
      switchMap(thingies$ => thingies$.length > 0 ? combineLatest(thingies$) : of([]))
    );

    return this.recentThingieList$$;
  }

  getRecentThingieList$(): Observable<IThingie[]> {
    return this.recentThingieList$;
  }

  getThingieById(thingieId: string, writable?: boolean, initial?: IThingie): Observable<IThingie> {
    if (thingieId.length === 0) {
      throw new Error('Thingie without an id requested');
    }

    const thingie$ = this.thingieCache.get(thingieId);
    if (thingie$ !== undefined) {
      if (writable === true) {
        // return a copy
        return thingie$
          .pipe(switchMap(thingie => from(deepCopy(thingie))));
      }

      // return the original object, which is a deepFrozen object that cannot be changed
      return thingie$;
    }

    const backendRequest$ = initial !== undefined ? of(initial) : from(this.dataloader.load(thingieId))
      .pipe(switchMap(thingie => thingie ? of(thingie) : throwError(() => new Error('Thingie not found with id ' + thingieId))));
    const websocketUpdate$ = this.subscribeUpdates(thingieId)
      .pipe(
        map(dataEvent =>  dataEvent.data)
      );
    const thingieAndUpdates$: Observable<IThingie> = merge(backendRequest$, websocketUpdate$).pipe(
      finalize(() => this.thingieCache.delete(thingieId)),
      delayedShareReplay(DefaultConfig.thingie.shareReplayDelaySeconds * 1000, 1),
      map(thingie => deepFreeze(thingie)),
    );

    this.thingieCache.set(thingieId, thingieAndUpdates$);
    return thingieAndUpdates$.pipe(switchMap(thingie => from(deepCopy(thingie))));
  }

  getRelationsOfThingieById(thingieId: string): Observable<IThingie[]> {
    if (thingieId.length === 0) {
      throw new Error('Relations of thingie without an id requested');
    }

    // fetch the relations
    return this.httpClient.get<IThingie[]>(DefaultConfig.uris.thingieRelations.replace('{thingieId}', encodeURIComponent(thingieId)));
  }

  getAvailableDataSeriesFromThingie(thingie: IThingie, processIdx?: number, taskIdx?: number): IDataSeries[] {
    this.log.debug(`ThingieService::getAvailableDataSeriesFromThingie() - thingie w. id ${thingie._id}`);
    const seriesList: IDataSeries[] = [];

    let filteredProcesses: IProcess[] = [];
    if (processIdx !== undefined) {
      filteredProcesses = [thingie.processes[processIdx]];
    } else {
      filteredProcesses = thingie.processes;
    }
    filteredProcesses = filteredProcesses.filter(process => process.removed !== true);

    if (filteredProcesses !== undefined) {
      filteredProcesses.forEach((process, pIndex) => {
        let filteredTasks: ITask[] = [];
        if (!process) {
          return;
        }
        if (taskIdx !== undefined) {
          filteredTasks.push(process.tasks[taskIdx]);
        } else {
          filteredTasks = process.tasks;
        }
        filteredTasks = filteredTasks.filter(task => task.removed !== true);
        if (filteredTasks !== undefined) {
          filteredTasks.forEach((task, oIndex) => {
            if (task === undefined) {
              return;
            }
            Object.entries(task.outputs).forEach(([outputKey, output]) => {
              if (output.disabled === true) {
                return;
              }
              for (const propertyToPlot of ['value', 'values']) {
                if (output.items[propertyToPlot]?.visibility === 'hidden') {
                  continue;
                }
                // For scatter plots, the y dimension is always the first dimension of the 'value' item
                const yDimension = output.items[propertyToPlot]?.valueInfo[0];
                if (!yDimension) {
                  // This is most likely not suited for a scatterplot
                  continue;
                }
                const zDimension = output.items[propertyToPlot]?.structureInfo[0];
                const newSeries  = createDefaultThingieSeries();
                if (zDimension !== undefined) {
                  newSeries.valueConfig = createDefaultArrayValuesConfiguration();
                }
                if (newSeries.identification.type !== 'thingieSeries') {
                  throw new Error('Impossible series identification type');
                }

                newSeries.identification.dimensionName    = output.name ?? outputKey;
                newSeries.identification.output           = outputKey;
                newSeries.identification.processIndex     = processIdx === undefined ? pIndex : processIdx;
                newSeries.identification.taskIndex = taskIdx === undefined ? oIndex : taskIdx;
                newSeries.identification.thingieId         = thingie._id as string;

                if (newSeries.valueConfig.type === 'scatterValues' || newSeries.valueConfig.type === 'arrayValues') {
                  newSeries.valueConfig.unit = yDimension.unit;
                }
                newSeries.description = yDimension.name;
                newSeries.dataItemDescription = output.items;

                seriesList.push(newSeries);
              }
            });
            Object.entries(task.inputs).forEach(([inputKey, input]) => {
              if (input.source !== 'offlinedata') {
                return;
              }
              if (input.item.visibility === 'hidden') {
                return;
              }
              // For scatter plots, the y dimension is always the first dimension of the 'value' item
              const yDimension = input.item.valueInfo[0];
              if (!yDimension) {
                // This is most likely not suited for a scatterplot
                return;
              }
              const zDimension = input.item.structureInfo[0];
              const newSeries  = createDefaultThingieSeries();
              if (zDimension !== undefined) {
                newSeries.valueConfig = createDefaultArrayValuesConfiguration();
              }
              if (newSeries.identification.type !== 'thingieSeries') {
                throw new Error('Impossible series identification type');
              }

              newSeries.identification.dimensionName    = input.name ?? inputKey;
              newSeries.identification.output           = inputKey;
              newSeries.identification.processIndex     = processIdx === undefined ? pIndex : processIdx;
              newSeries.identification.taskIndex = taskIdx === undefined ? oIndex : taskIdx;
              newSeries.identification.thingieId         = thingie._id as string;
              newSeries.identification.offlineData       = true;

              if (newSeries.valueConfig.type === 'scatterValues' || newSeries.valueConfig.type === 'arrayValues') {
                newSeries.valueConfig.unit = yDimension.unit;
              }
              newSeries.description = yDimension.name;
              newSeries.dataItemDescription = { value: input.item };

              seriesList.push(newSeries);
            });
          });
        }
      });
    }

    return seriesList;
  }

  getAnnotationSeriesFromThingie( thingie: IThingie):  AnnotationSeries[] {
    const annotationSerieAsSystem = createDefaultAnnotationDataSeries();
    const annotationSerieAsUser = createDefaultAnnotationDataSeries();

    if (thingie._id === undefined) {
      return [];
    }
    annotationSerieAsSystem.identification.thingieId = thingie._id;
    annotationSerieAsUser.identification.thingieId = thingie._id;

    // TODO: create better place to set dimension, unit and others for
    // series.valueConfig....

    annotationSerieAsUser.description = 'Annotation Series created as User';
    annotationSerieAsUser.identification.systemAnnotation = false;


    annotationSerieAsSystem.description = 'Annotation Series created by System';
    annotationSerieAsSystem.identification.systemAnnotation = true;

    return [annotationSerieAsUser, annotationSerieAsSystem];
  }

  async createThingie(thingie: IThingie): Promise<IThingie> {
    return (await lastValueFrom(this.httpClient.post<IThingie>(
      DefaultConfig.uris.thingieList,
      thingie
    )))!;
  }

  async updateThingie(thingie: IThingie): Promise<IThingie> {
    if (thingie._id === undefined) {
      throw new Error('Cannot update thingie without an id');
    }
    return (await lastValueFrom(this.httpClient.post<IThingie>(
      DefaultConfig.uris.thingie.replace('{thingieId}', thingie._id),
      thingie
    )))!;
  }

  async batchUpdateThingies(thingies: IThingie[]): Promise<IThingie[]> {
    return (await lastValueFrom(this.httpClient.post<IThingie[]>(
      DefaultConfig.uris.thingie_batchUpdate,
      thingies
    )))!;
  }

  async createAnnotations(
    body: { thingies: string[]; annotations: IAnnotation[] }
  ): Promise<ICreateAnnotationsResult> {
    return (await lastValueFrom(this.httpClient.post<ICreateAnnotationsResult>(
      DefaultConfig.uris.thingieCreateAnnotations,
      body
    )))!;
  }

  async editAnnotation(thingieId: string, annotationId: number, annotation: IAnnotation): Promise<IThingie> {
    if (thingieId === undefined || annotationId === undefined || annotation === undefined) {
      throw new Error('Cannot update annotation');
    }
    return (await lastValueFrom(this.httpClient.post<IThingie>(
      DefaultConfig.uris.thingie.replace('{thingieId}', thingieId) + `/processes/0/annotations/${annotationId}`,
      annotation
    )))!;
  }

  async deleteAnnotations(thingieId: string, annotationId: number): Promise<IThingie> {
    if (thingieId === undefined || annotationId === undefined) {
      throw new Error('Cannot delete annotation');
    }
    return (await lastValueFrom(this.httpClient.delete<IThingie>(
      DefaultConfig.uris.thingie.replace('{thingieId}', thingieId) + `/processes/0/annotations/${annotationId}`
    )))!;
  }

  archiveThingie(thingieId: string): Promise<void> {
    return lastValueFrom(this.httpClient.post<void>(
      DefaultConfig.uris.thingieArchive.replace('{thingieId}', thingieId), {}
    ));
  }

  unarchiveThingie(thingieId: string): Promise<void> {
    return lastValueFrom(this.httpClient.delete<void>(
      DefaultConfig.uris.thingieArchive.replace('{thingieId}', thingieId), {}
    ));
  }

  /**
   * Upload specified file to backend. Report on progress via the Observable
   *
   * @param thingieId target thingie
   * @param annotationId target annotation
   * @param file file to upload
   */
  async uploadAttachment(thingieId: string, annotationId: number, file: File[]): Promise<IAnnotationAttachment[]> {
    if (thingieId === undefined || annotationId === undefined || file === undefined) {
      throw new Error('Cannot upload attachment');
    }

    const returnData: IAnnotationAttachment[] = [];
    const promises = file.map(async file => {
      const formData = new FormData();
      formData.append('file', file);

      const fileUploadResponse = (await lastValueFrom(this.httpClient.post<IAnnotationAttachment>(
        DefaultConfig.uris.thingie.replace('{thingieId}', thingieId) +
              `/processes/0/annotations/${annotationId}/files`,
        formData
      )))!;

      returnData.push(fileUploadResponse);
    });

    await Promise.all(promises);

    return returnData;
  }

  async executeProcessAction(action: string, thingieId: string, processIdx: number): Promise<IThingie> {
    return (await lastValueFrom(this.httpClient.post<any>(
      DefaultConfig.uris.thingieProcess
        .replace('{thingieId}', thingieId)
        .replace('{processIdx}', processIdx.toString())
        .replace('{action}', action),
      {}
    )))!;
  }

  async executeTaskAction(action: string, thingieId: string, processIdx: number, taskIdx: number): Promise<any> {
    return (await lastValueFrom(this.httpClient.post<any>(
      DefaultConfig.uris.thingieTask
        .replace('{thingieId}', thingieId)
        .replace('{processIdx}', processIdx.toString())
        .replace('{taskIdx}', taskIdx.toString())
        .replace('{action}', action),
      {}
    )))!;
  }

  async exportThingieData(data: DataExportDTO, abortSignal?: AbortSignal): Promise<{ data: Blob | null; type: 'raw' | 'zip'; fileName: string | null }> {
    const acceptHeader = new HttpHeaders({
      Accept: [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/json'
      ]
    });

    const request$ = this.httpClient.post(
      DefaultConfig.uris.thingieDataExport,
      data,
      { headers: acceptHeader, observe: 'response', responseType: 'blob' }
    ).pipe(switchMap(response => {
      // response is a multipart/form-data with a "zip" part and a final "error" part.
      // The "stream" part is the thing to download, and the "error" part contains either
      // "null" or an error that happened during the download.
      // This is necessary because once streaming of a response has started, there
      // is no way to send another http status, or undo the sending. But if an error
      // happens, the streaming of the response just stops. So we are including a second
      // part to the answer that is sent after the streaming of the file finishes,
      // and contains information about whether the export was successful or not.
      const contentType = response.headers.get('Content-Type');
      const body = response.body;
      if (contentType === null || body === null) {
        return of(response);
      }
      return this.parseMultipart(body, contentType)
        .pipe(map(parts => {
          const error = parts.get('error');
          if (error !== undefined) {
            const errorText = new TextDecoder().decode(error.data);
            if (!errorText.startsWith('null')) {
              throw new Error(errorText);
            }
          }
          const streamPart = parts.get('stream');
          if (streamPart === undefined) {
            throw new Error('Need a stream file in response');
          }
          const { type, data: stream } = streamPart;

          let headers = response.headers;
          if (type !== undefined) {
            headers = headers.set('Content-Type', type);
          }

          const newResponse = response.clone({
            body: new Blob([stream]),
            headers
          });
          return newResponse;
        }));
    }));

    const res = (await this.startCancelableDownload(
      request$,
      abortSignal,
      true
    )) as { data: Blob | null; headers: HttpHeaders | null };

    if (
      res.headers
      && res.headers.has('Content-Type')
    ) {
      const contentDispositionValue = res.headers.get('content-disposition');
      let fileName = null;
      if (contentDispositionValue) {
        //Extract the filename. The Backend returns the value of the header with the structure: 'attachment; filename="<name>.zip"'
        fileName = contentDispositionValue?.replace('attachment; ', '');
        fileName = fileName.replace('filename=', '');
        fileName = fileName.substring(1, fileName.length - 1);
      }

      const contentTypeValue = res.headers.get('Content-Type');
      if (contentTypeValue === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        return {
          fileName,
          data: res.data,
          type: 'raw'
        };
      } else if (contentTypeValue === 'application/octet-stream') {
        return {
          fileName,
          data: res.data,
          type: 'zip'
        };
      }
    }

    return {
      fileName: null,
      data: null,
      type: 'raw'
    };
  }

  /**
   * Parse multipart/form-data response. Split body at the boundary string provided in the
   * Content-Type header. Split each part into header and payload at `\r\n\r\n` separator.
   * Extract part name from Content-Disposition part header.
   *
   * @param body response body blob
   * @param contentType Content-Type header of response
   * @returns Map of multipart part names to part payloads
   */
  parseMultipart(body: Blob, contentType: string) {
    const decoder = new TextDecoder();
    const encoder = new TextEncoder();
    const boundary = '\r\n--' + contentType.split('boundary=')[1];
    const boundaryBytes = new Uint8Array(encoder.encode(boundary));
    // in multipart part, header is before `\r\n\r\n`, content after
    const payloadStartBytes = new Uint8Array(encoder.encode('\r\n\r\n'));
    return from(body.arrayBuffer()).pipe(map(buf => {
      const boundaryIndices = searchBytes(boundaryBytes, new Uint8Array(buf));
      let lastBoundary = 0;
      const parts = new Map<string, { type: string | undefined; data: ArrayBuffer }>();
      for (const boundaryIdx of boundaryIndices) {
        const part = buf.slice(lastBoundary, boundaryIdx);
        lastBoundary = boundaryIdx + boundaryBytes.length;
        const payloadStartIdx = searchBytes(payloadStartBytes, new Uint8Array(part), 1);
        if (payloadStartIdx[0] === undefined) {
          // skip parts without payload separator
          continue;
        }
        const header = decoder.decode(part.slice(0, payloadStartIdx[0]));
        const name = header.match(/[\r\n]Content-Disposition: .*; name="(.*)"/);
        const type = header.match(/[\r\n]Content-Type: (.*)[\r\n]/);
        if (name === null) {
          // skip parts without name
          continue;
        }
        parts.set(name[1], {
          type: type?.[1] ?? undefined,
          data: part.slice(payloadStartIdx[0] + payloadStartBytes.length)
        });
      }
      return parts;
    }));
  }

  /**
   * Start a cancelable download
   *
   * @param url
   * @param payload
   * @param acceptHeader
   * @param abortSignal
   * @param returnHeaders
   * @returns Either `Promise<Blob | null>` when `returnHeaders` is not provided
   *    or `Promise<{data: Blob | null; headers: HttpHeaders | null}>` when `returnHeaders` is `true`.
   */
  private async startCancelableDownload(
    request$: Observable<HttpResponse<Blob>>,
    abortSignal?: AbortSignal,
    returnHeaders?: true
  ): Promise<(Blob | null) | { data: Blob | null; headers: HttpHeaders | null }> {
    // `null` as indicator for download-abortion
    const res = await new Promise<HttpResponse<Blob> | null>((resolve, reject) => {
      let sub: Subscription;

      // To be called if the abort signal is fired
      const abortListener = () => {
        sub.unsubscribe();
        resolve(null);
      };

      sub = request$.subscribe({
        error: (err: unknown) => {
          abortSignal?.removeEventListener('abort', abortListener);
          reject(err);
        },
        next: data =>  {
          abortSignal?.removeEventListener('abort', abortListener);
          resolve(data);
        }
      });

      if (abortSignal) {
        abortSignal.addEventListener('abort', abortListener);
      }
    });

    if (res !== null) {
      if (res.body === null) {
        throw new Error('No file was produced');
      }

      if (returnHeaders) {
        return {
          data: res.body,
          headers: res.headers
        };
      }

      return res.body;
    } else {
      if (returnHeaders) {
        return {
          data: null,
          headers: null
        };
      }

      return null;
    }
  }

  async downloadAnnotationAttachment(annotationAttachment: IAnnotationAttachment): Promise<void> {
    if (annotationAttachment === undefined) {
      throw new Error('Cannot download annotation');
    }
    // downloads file without extensions somehow eventhough filename with correct extension is provided
    FileSaver.saveAs(`${DefaultConfig.uris.thingie.split('/api')[0]}/uploads/${annotationAttachment.sha256Hash}`,
      annotationAttachment.originalFileName);
  }

  async imagePreview(): Promise<string> {
    return `${DefaultConfig.uris.thingie.split('/api')[0]}/uploads/`;
  }

  subscribeUpdates(thingieId?: string): Observable<IDataEvent<IThingie>> {
    const requestFilter: IThingieFilter = {
      filterType: 'ThingieFilter',
      _id: thingieId
    };

    return this.websocketConnection.subscribeWithFilter<IThingie>(this.websocketUri, requestFilter)
      .pipe(bufferWhileTabIsHidden());
  }

  getVersion(): Observable<string> {
    return this.httpClient.get(
      DefaultConfig.uris.version.dataprocessing,
      {
        responseType: 'text'
      }
    );
  }

  getTags(): Observable<string[]> {
    return this.httpClient.get<string[]>(
      DefaultConfig.uris.thingieTagsList
    );
  }

  getOfflineDataUnits(): Observable<string[]> {
    return this.httpClient.get<string[]>(
      DefaultConfig.uris.thingieOfflineDataUnits
    );
  }

  /**
   * Upload specified file to backend. Report on progress via the Observable
   *
   * @param thingieId target thingie
   * @param annotationId target annotation
   * @param file file to upload
   */
  async importThingie(files: File[], replacementProjectId: string | undefined, replacementTeamId: string | undefined): Promise<any> {
    if (files === undefined) {
      throw new Error('No files selected');
    }

    for (const file of files) {
      const formData = new FormData();
      formData.append('file', file);
      if (replacementProjectId) {
        formData.append('replacementProjectId', replacementProjectId);
      }
      if (replacementTeamId) {
        formData.append('replacementTeamId', replacementTeamId);
      }

      (await lastValueFrom(this.httpClient.post<any>(
        DefaultConfig.uris.thingieDatabaseImport,
        formData
      )))!;
    }
  }

  async batchCreateThingies(thingies: IThingie[]): Promise<IThingie[]> {
    return (await lastValueFrom(this.httpClient.post<IThingie[]>(
      DefaultConfig.uris.thingie_batchCreate,
      thingies
    )))!;
  }

  checkForTakenNames(entries: { id?: string; name: string }[]): Observable<string[]> {
    return this.httpClient.post<string[]>(
      DefaultConfig.uris.thingie_checkTakenExperimentNames,
      entries
    );
  }

  checkForTakenThingieNames(entries: { id?: string | undefined; name: string }[]): Observable<string[]> {
    return this.httpClient.post<string[]>(
      DefaultConfig.uris.thingie_checkTakenThingieNames,
      entries
    );
  }

  batchSetTargetStates(
    dto: ISetTargetStateEntry[]
  ): Observable<void> {
    return this.httpClient.post<void>(
      DefaultConfig.uris.thingieTask_batchSetStates,
      dto
    );
  }

  getMostRecentPagination(): IThingieMostRecentPagination {
    return this.mostRecentThingiesPagination;
  }

  removeAttachments(param: IAttachmentLocation[]): Promise<void> {
    return lastValueFrom(this.httpClient.post<void>(
      DefaultConfig.uris.thingieAttachmentRemoval,
      param
    ));
  }

  undeleteAttachments(attachmentLocations: IAttachmentLocation[]): Promise<void> {
    return lastValueFrom(this.httpClient.post<void>(
      DefaultConfig.uris.thingieAttachmentUndelete,
      attachmentLocations
    ));
  }

  async createAttachmentZipFile(hashes: string[]): Promise<string> {
    const response = (await lastValueFrom(this.httpClient.post(
      DefaultConfig.uris.thingieCreateAttachmentZip,
      hashes,
      { observe: 'response' }
    )))!;

    const location = response.headers.get('location');
    if (!location) {
      throw new Error('No location header found');
    }

    return location;
  }
}
