
export class LocalStorageHelper {
  static getItem(prefix: string, currentUserID: string, key: string): string | null {
    const settingStr = localStorage.getItem(prefix + '-' + currentUserID);
    let setting: { [key: string]: any } = {};

    if (settingStr === null) {
      return null;
    }

    setting = JSON.parse(settingStr);

    if (setting[key]) {
      return setting[key].toString();
    }

    return null;
  }

  static updateItem(prefix: string, currentUserID: string, key: string, value: string) {
    const settingStr = localStorage.getItem(prefix + '-' + currentUserID);
    let setting: { [key: string]: any } = {};

    if (settingStr !== null) {
      setting = JSON.parse(settingStr);
    }

    setting[key] = value;
    localStorage.setItem(prefix + '-' + currentUserID, JSON.stringify(setting, null, 2));
  }

  static setItem(prefix: string, currentUserID: string, value: string) {
    localStorage.setItem(prefix + '-' + currentUserID, value);
  }


  static deleteItem(prefix: string, currentUserID: string, key: string) {
    const settingStr = localStorage.getItem(prefix + '-' + currentUserID);
    let setting: { [key: string]: any } = {};

    if (settingStr !== null) {
      setting = JSON.parse(settingStr);
    }

    // check if exists then delete
    if (key in setting) {
      delete setting[key];
    }

    localStorage.setItem(prefix + '-' + currentUserID, JSON.stringify(setting, null, 2));
  }
}
