import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Injectable, inject } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MaterialImportsModule } from 'src/app/material-imports.module';

@Component({
  imports: [
    CommonModule,
    MaterialImportsModule,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <h2 mat-dialog-title>Setpoint type explanation</h2>
    <mat-dialog-content>
      <div>
        There are two different types of setpoints:

        <ul>
          <li>Machine setpoints</li>
          <li>Software setpoints</li>
        </ul>
      </div>
      <p>
        <strong>Machine setpoints</strong> are setpoints that exist in the shaker and will make the shaker
        attempt to reach the desired value.
      </p>
      <p>
        <strong>Software setpoints</strong> are setpoints which are configured in DOTS.
        When the software setpoint is changed, the new setpoint is sent to the shaker.
      </p>
      <p>
      A difference between the two setpoints can indicate that the machine setpoint has been changed manually at the shaker after the software setpoint was sent.
      </p>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button mat-flat-button color="primary" mat-dialog-close>Close</button>
    </mat-dialog-actions>
  `,
})
export class ShakerSetpointTypeExplanationDialogComponent {

}


/**
 * Utility service for opening a dialog to add new devices.
 *
 * {@link AddDeviceDialogComponent}
 */
@Injectable({ providedIn: 'root' })
export class ShakerSetpointTypeExplanationDialogComponentService {
  private dialog = inject(MatDialog);

  open() {
    return this.dialog.open<
      ShakerSetpointTypeExplanationDialogComponent,
      void,
      undefined>(ShakerSetpointTypeExplanationDialogComponent);
  }
}
