import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReplaySubject, lastValueFrom, of, throwError } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { IonicImports, MaterialImportsModule } from 'src/app/material-imports.module';
import { IConfigurationItem } from 'src/app/model/device/IConfigurationItem';
import { IPojoDevice } from 'src/app/model/device/IPojoDevice';
import { DeviceService } from 'src/app/services/device/device.service';
import { LogService } from 'src/app/services/loggers/logger.service';
import { UINotificationService } from 'src/app/services/ui-notification/uinotification.service';
import { deepCopy } from 'src/app/utility/deep-copy';
import { ShakerSetpointTypeExplanationDialogComponentService } from '../../shaker-setpoint-type-explanation-dialog/shaker-setpoint-type-explanation-dialog.component';


interface IConnectionInfo {
  ip: string;
  port: number;
}


interface IFeatureInfo {

  /**
   * The parameter key in the configuration and packet content description.
   */
  parameterKey: string;

  /**
   * Error while reading data.
   *
   * If `true`, setpoint and `value` are not reliable.
   */
  hasError: boolean;

  description: string;
  unit: string;
  value: any;
  reportedSetpoint: any;
  configuredSetpoint: any;
  newSetpoint?: any;
  enabled: boolean;
}


/**
 * Combined information about the shaker.
 */
interface ICombinedInfo {
  connection: IConnectionInfo;


  features: IFeatureInfo[];


  /**
   * If the shaker has a door, indicates if the door is open.
   */
  doorOpen?: boolean;
}


@Component({
  selector: 'app-shaker-parameter-control-view',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MaterialImportsModule,
    IonicImports,
  ],
  templateUrl: './shaker-parameter-control-view.component.html',
  styles: [
    ':host { display: block; }'
  ],
})
export class ShakerParameterControlViewComponent {
  @Input({ required: true })
  set deviceId(value: string) {
    this.deviceId$.next(value);
  }


  @Input()
  mode: 'view' | 'control' = 'view';


  private deviceService = inject(DeviceService);


  private log = inject(LogService);


  private uiNotifications = inject(UINotificationService);


  private deviceId$ = new ReplaySubject<string>(1);


  private setpointTypeExplanationDialog = inject(ShakerSetpointTypeExplanationDialogComponentService);


  /**
   * @private
   *
   * Indicates if user-provided setpoints are invalid
   */
  _hasInvalidSetpoints = false;


  _userSetpoints: {
    [key: string]: {
      reportedSetpoint: string | number | null;
      configuredSetpoint: string | number | null;
      newSetpoint?: string | number | null;
      enabled: boolean;
    };
  } = {};


  /**
   * The device for which to control setpoints
   */
  private device?: IPojoDevice;


  _deviceInfo$ = this.deviceId$.pipe(
    switchMap(
      deviceId => this.deviceService.getDevice(deviceId).pipe(
        switchMap(device => {
          if (device.devType !== 'KuhnerShaker') {
            return throwError(() => new Error('Device is not a Kuhner shaker'));
          }
          return of(device);
        }),
      )
    ),
    tap(device => this.device = device),
    map<IPojoDevice, ICombinedInfo>(device => {
      // Check packet content description and save property information

      const connectionDescription = device.packetContentDescription.TcpConnection;
      const parameterDescription  = device.packetContentDescription.KuhnerShakerLTXParameterReport;
      const statusDescription     = device.packetContentDescription.KuhnerShakerLTXStatus;
      const setpointDescription   = device.packetContentDescription.KuhnerShakerLTXSetpoints;

      if (!connectionDescription || !parameterDescription || !statusDescription) {
        throw new Error('Device does not have required packet content descriptions');
      }

      // Check configuration and save configuration values

      let connectionValue: IPojoDevice['configuration']['TcpConnection'] | undefined;
      let setpointValue: IPojoDevice['configuration']['KuhnerShakerLTXSetpoints'] | undefined;
      let parameterValue: IPojoDevice['info']['KuhnerShakerLTXParameterReport'] | undefined;
      let statusValue: IPojoDevice['info']['KuhnerShakerLTXStatus'] | undefined;

      Object.entries(device.configuration).forEach(([_, value]) => {
        if (value.type === 'TcpConnection') {
          connectionValue = value;
        }
        if (value.type === 'KuhnerShakerLTXSetpoints') {
          setpointValue = value;
        }
      });
      parameterValue = device.info.KuhnerShakerLTXParameterReport;
      statusValue = device.info.KuhnerShakerLTXStatus;

      if (!connectionValue || !parameterValue || !statusValue || !setpointValue) {
        throw new Error('Device does not have required configuration values');
      }

      // Get required values

      const connectionInfo = this.getConnectionInfo(connectionValue);
      const featureInfo = this.getFeatureInfo({
        parameterDescription,
        parameterConfiguration: parameterValue,
        statusDescription,
        statusConfiguration: statusValue,
        setpointDescription,
        setpointConfiguration: setpointValue,
      });

      return {
        connection: connectionInfo,
        features: featureInfo.features,
        doorOpen: featureInfo.doorOpen
      };
    }),
    tap(combinedInfo => {
      // Pre-set user setpoints
      combinedInfo.features.forEach(feature => {
        if (this._userSetpoints[feature.parameterKey] === undefined) {
          this._userSetpoints[feature.parameterKey] = {
            reportedSetpoint: feature.reportedSetpoint,
            configuredSetpoint: feature.configuredSetpoint,
            enabled: feature.enabled
          };
        }
      });
    })
  );


  private getConnectionInfo(
    configuration: IConfigurationItem
  ): IConnectionInfo {
    if (configuration.type !== 'TcpConnection') {
      throw new Error('Configuration item is not a TCP connection');
    }

    return {
      ip: configuration.values!.ipAddress as string,
      port: configuration.values!.port as number
    };
  }


  private getFeatureInfo(input: {
    parameterDescription: IPojoDevice['packetContentDescription']['KuhnerShakerLTXParameterReport'];
    parameterConfiguration: IPojoDevice['info']['KuhnerShakerLTXParameterReport'];

    statusDescription: IPojoDevice['packetContentDescription']['KuhnerShakerLTXStatus'];
    statusConfiguration: IPojoDevice['info']['KuhnerShakerLTXStatus'];

    setpointDescription: IPojoDevice['packetContentDescription']['KuhnerShakerLTXSetpoints'];
    setpointConfiguration: IPojoDevice['configuration']['KuhnerShakerLTXSetpoints'];
  }): { features: IFeatureInfo[]; doorOpen?: boolean } {
    // Get required feature information

    const featureInfo: IFeatureInfo[] = [];

    for (const [paramKey, paramItem] of Object.entries(input.parameterDescription.items)) {
      // Skip setpoint descriptors
      if (paramKey.endsWith('Setpoint')) {
        continue;
      }

      const description        = paramItem.valueInfo[0].name + ' [' + paramItem.valueInfo[0].unit + ']';
      const value              = input.parameterConfiguration.values![paramKey];
      const reportedSetpoint   = input.parameterConfiguration.values![paramKey + 'Setpoint'];
      const configuredSetpoint = input.setpointConfiguration.values![paramKey + 'Setpoint'];
      const enabled            = input.statusConfiguration.values![paramKey + 'Enabled'] as boolean;
      const hasError           = input.statusConfiguration.values![paramKey + 'Error'] as boolean;

      featureInfo.push({
        parameterKey: paramKey,
        description,
        unit: paramItem.valueInfo[0].unit,
        value,
        reportedSetpoint,
        configuredSetpoint,
        enabled,
        hasError
      });
    }

    // Special entries

    // Is door open?
    let isOpen = false;
    if (input.statusConfiguration.values!.isOpen) {
      isOpen = input.statusConfiguration.values!.isOpen as boolean;
    }

    return {
      features: featureInfo,
      doorOpen: isOpen
    };
  }


  /**
   * @private
   */
  async _onConfirmNewSetpoints() {
    if (this.device === undefined) {
      throw new Error('Can´t update device: device is not defined');
    }

    // Prepare new configuration

    const deviceUpdate = await deepCopy(this.device);

    // Get reference to parameter configuration object
    let parameterConfiguration: IPojoDevice['configuration']['KuhnerShakerLTXSetpoints'];
    for (const value of Object.values(deviceUpdate.configuration)) {
      if (value.type === 'KuhnerShakerLTXSetpoints') {
        parameterConfiguration = value;
        break;
      }
    }

    for (const [paramKey, paramValue] of Object.entries(this._userSetpoints)) {
      parameterConfiguration!.values![paramKey + 'Setpoint'] = paramValue.newSetpoint;
    }

    // Send new configuration

    try {
      (await lastValueFrom(this.deviceService.postDevice(deviceUpdate)))!;

      this.mode = 'view';
    } catch (e) {
      const message = `Failed to update device configuration of device ${this.device.address}`;
      this.log.error(message, e);
      this.uiNotifications.displayErrorAlert({
        title: 'Error: configuration update',
        message,
      }, e);
    }
  }


  /**
   * @private
   * @param feature The feature for which to change the setpoint
   * @param newValue The new value for the setpoint
   */
  _onChangeSetpoint(
    feature: IFeatureInfo,
    newValue: string | number | null
  ) {
    this._userSetpoints[feature.parameterKey].newSetpoint = newValue;

    // Check if setpoints are valid
    this._hasInvalidSetpoints = Object.values(this._userSetpoints).some(value => {
      if (value.newSetpoint === null) {
        return true;
      }

      if (typeof value.newSetpoint === 'number') {
        return isNaN(value.newSetpoint);
      }

      return false;
    });
  }


  _onSwitchToControlMode() {
    Object.values(this._userSetpoints).forEach(feature => {
      if (feature.newSetpoint === undefined) {
        feature.newSetpoint = feature.configuredSetpoint;
        if (feature.newSetpoint === -1) {
          feature.newSetpoint = null;
        }
      }
    });
    this.mode = 'control';
  }


  /**
   * @private
   *
   * Note: currently does nothing
   * @param feature
   * @param enabled
   */
  _onChangeFeatureState(feature: IFeatureInfo, enabled: boolean) {
    this._userSetpoints[feature.parameterKey].enabled = enabled;
  }


  /**
   * @private
   * @param _ ignored
   * @param item The feature item to identify
   * @returns Parameter key of the feature as the main identifier in the list
   */
  _trackByFn(_: number, item: IFeatureInfo): string {
    return item.parameterKey;
  }


  /**
   * Displays a dialog with information about the different setpoint types.
   *
   * @private
   */
  _explainSetpointTypes() {
    this.setpointTypeExplanationDialog.open();
  }
}
