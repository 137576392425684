<div 
  class="wrapper"
  [ngClass]="{
    'single-line': !isMultilineActions,
    'multi-line': isMultilineActions
  }"
>
  <div class="flex">
    <div class="message-bullet-point-wrapper" *ngIf="options.defaultUI !== false">
      <div class="bullet"></div>
    </div>
    <div class="flex flex-col">
      <b *ngIf="options.heading">{{options.heading}}</b>

      <span class="message">
        {{ options.message }}
      </span>
    </div>
  </div>

  <div *ngIf="options.actionButtons" class="action-wrapper">
    <ng-container *ngFor="let action of options.actionButtons">
      <button
        *ngIf="action.buttonType === undefined || action.buttonType === 'text'"
        mat-button
        [color]="action.color ?? ''"
        (click)="onAction(action)"
      >
        {{ action.text }}
      </button>
      <button
        *ngIf="action.buttonType === 'flat'"
        mat-flat-button
        [color]="action.color ?? 'primary'"
        (click)="onAction(action)"
      >
        {{ action.text }}
      </button>
    </ng-container>
  </div>


  <div class="close-icon-button" *ngIf="options.closeButton === true">
    <button
      mat-icon-button
      (click)="closeAction()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
