import { AfterViewInit, Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

/**
 * Alternative to window.prompt().
 *
 * Can be
 *
 * When closed the result returned is either the text
 * entered or {undefined}.
 */
@Component({
  templateUrl: './prompt-dialog.component.html',
  styleUrls: [
    './prompt-dialog.component.scss'
  ]
})
export class PromptDialogComponent implements AfterViewInit {
  textControl = new FormControl(
    '',
    [
      Validators.required
    ]
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public prompt: { title: string; _default?: string },
    private dialogRef: MatDialogRef<PromptDialogComponent>
  ) {
    this.textControl.setValue(prompt._default ?? null);
  }

  ngAfterViewInit() {
    this.textControl.updateValueAndValidity();
  }

  onCancel() {
    this.dialogRef.close(undefined);
  }

  onFinish() {
    if (this.textControl.valid) {
      this.onSubmit();
    }
  }

  onSubmit() {
    const text = this.textControl.value;

    this.dialogRef.close(text);
  }
}
