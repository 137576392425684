import { IPojoDevice } from '../model/device/IPojoDevice';

/**
 * Return the value behind a shortcut
 *
 * heavily inspired from from https://stackoverflow.com/a/37510735
 *
 * @param device
 * @param shortCut name of the shortcut to follow
 * @param defaultValue return this if following the shortcut fails
 * @returns the target object of the shortcut
 */
export function resolveShortcut(device: IPojoDevice, shortCut: string, defaultValue?: any): any {
  // look up the property path, return the default value if it does not exist
  const propertyPath = device.shortcuts[shortCut];
  if (!propertyPath) {
    return defaultValue;
  }
  if (propertyPath.indexOf('.') === -1) {
    // there are no dots in this path, that means its most likely not a
    // shortcut, but a literal value. This is up for discussion how to handle
    // this case.
    return propertyPath;
  }

  // Follow the dot notation and return the target object
  const val = propertyPath.split('.').reduce(function(prev: any, curr: any) {
    return prev ? prev[curr] : undefined;
  }, device);
  // its possible that we were not able to resolve the shortcut, in this case, return the default value
  return val !== undefined ? val : defaultValue;
}
