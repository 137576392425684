import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA, MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';

type ButtonType = 'flat' | 'text';

interface ActionButton {
  text: string;
  actionsToExecute: (() => void)[];
  buttonType?: ButtonType;
  color?: 'primary' | 'accent' | 'warn';
}

export interface ISnackbarDefaultContentContainerOptions {
  heading?: string;
  message: string;
  actionButtons?: ActionButton[];
  actionsPosition?: 'right' | 'below';
  defaultUI?: boolean;
  closeButton?: boolean;
}

@Component({
  selector: 'app-snackbar-default-content-container',
  templateUrl: './snackbar-default-content-container.component.html',
  styleUrls: ['./snackbar-default-content-container.component.scss']
})
export class SnackbarDefaultContentContainerComponent {
  options: ISnackbarDefaultContentContainerOptions;

  isMultilineActions = false;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) private data: ISnackbarDefaultContentContainerOptions,
    private theSnack: MatSnackBarRef<SnackbarDefaultContentContainerComponent>
  ) {
    if (!data) {
      throw new Error('Options not provided');
    }

    this.options = data;

    if (data.actionButtons) {
      if (data.actionButtons.some(actionButton => actionButton.text.split(/\s+/).length > 1)) {
        this.isMultilineActions = true;
      }
      if (data.actionButtons.length > 1) {
        this.isMultilineActions = true;
      }
    }
    if (data.actionsPosition === 'right') {
      this.isMultilineActions = false;
    }
    if (data.actionsPosition === 'below') {
      this.isMultilineActions = true;
    }
  }

  onAction(actionButton: ActionButton) {
    for (const action of actionButton.actionsToExecute) {
      action();
    }

    this.theSnack.dismissWithAction();
  }

  closeAction() {
    this.theSnack.dismissWithAction();
  }
}
