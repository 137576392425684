<div
  class="flex flex-nowrap rounded overflow-hidden"
  [ngClass]="_mainClasses"
>
  <button
    class="s-button-defaults main flex flex-col justify-center"
    (click)="_onClick($event)"
    [disabled]="_disabled"
  >
    <ng-content select="[buttonContent]"></ng-content>
  </button>

  <div class="separator"></div>

  <button
    [matMenuTriggerFor]="menu"
    class="s-button-defaults menu"
    (click)="$event.stopPropagation();"
    [disabled]="_disabled"
  >
    <ion-icon
      name="chevron-down-outline"
    ></ion-icon>
  </button>
</div>

<mat-menu
  #menu="matMenu"
  class="split-button-menu-panel--border
  split-button-menu-panel--padding
  split-button-menu-panel--no-min-height"
  xPosition="before"
>
  <ng-content selector></ng-content>
</mat-menu>
