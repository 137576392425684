import { Injectable } from '@angular/core';
import { IUser } from 'src/app/model/user-management/user';
import { UserServiceImpl } from './implementation/user.service-impl';
import { Observable } from 'rxjs';

export interface ICreateUserDTO extends Omit<IUser, 'createdAt' | 'creator' | '_id'> {
  password: string;
}

export type IUserUpdateChanges = Omit<
  IUser,
'createdAt'
| 'creator'
>;

/**
 * UserService
 *
 * Notes
 * - Only those actions a user is permitted to do so
 * will work (e.g. only admins can create users).
 */
@Injectable({
  providedIn: 'root',
  useClass: UserServiceImpl
})
export abstract class UserService {

  /**
   * Returns saved pins observable
   *
   * @returns Observable of user pins map
   */
  abstract getSavedPins(): Observable<Map<string, number>>;

  /**
   * Returns pin map variable
   *
   */
  abstract getPinnedThingies(): Map<string, number>;

  /**
   * Updates Card Pin Map After Adding a Pin
   *
   */
  abstract pinThingies(relatives: string[]): void;

  /**
   * Updates Card Pin Map After Removing a Pin
   *
   */
  abstract unpinThingies(relatives: string[]): void;

  /**
   * Adds a pinned object from the database
   *
   */
  abstract addPinnedCard(thingieId: string): Promise<void>;

  /**
   * Removes a pinned object from the database
   *
   */
  abstract removePinnedCard(thingieId: string): Promise<void>;

  /**
   * Acquire a list of all user objects the current
   * user is allowed to access.
   */
  abstract getUserList(): Promise<IUser[]>;

  /**
   * Acquire a list of requested user objects the current
   * user is allowed to access.
   */
  abstract getUserListByIds(ids: string): Promise<IUser[]>;

  /**
   * Get user by ID
   *
   * @param opts
   */
  abstract getUserById(opts: { userId: string }): Promise<IUser>;

  /**
   * Create new user account
   *
   * @param opts
   */
  abstract createUser(user: ICreateUserDTO): Promise<IUser>;

  /**
   * Update user account
   *
   * @param user - Required user information
   */
  abstract updateUser(opts: {
    userId: string;
    changes: IUserUpdateChanges;
  }): Promise<void>;

  /**
   * (Re-)Enable user account
   *
   * @param opts
   */
  abstract unlockUser(opts: { userId: string }): Promise<void>;

  /**
   * Disable user account
   *
   * @param opts
   */
  abstract lockUser(opts: { userId: string }): Promise<void>;

  /**
   * Set user password
   *
   * @param opts
   */
  abstract setUserPassword(opts: { userId: string; password: string }): Promise<void>;

  /**
   * Log user out of all active sessions.
   *
   * @param opts
   */
  abstract forceLogout(opts: { userId: string }): Promise<void>;

  /**
   * Get current user
   *
   * @param opts
   */
  abstract getCurrentUser(): Promise<IUser>;
}
