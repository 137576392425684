import { LocalStorageHelper } from './local-storage-helper';

// general filters and sortings
export const DASHBOARD_PAGE_MODE_STORAGE_KEY = 'mode';
export const DASHBOARD_PAGE_GROUP_BY_STORAGE_KEY = 'group-by';


// view mode of items (thingies, cards)
export const DASHBOARD_PAGE_VIEW_MODE_STORAGE_KEY = 'view-mode';

// filter and sorting in cards view
export const DASHBOARD_PAGE_CARDS_PROJECT_STORAGE_KEY = 'cards-project';
export const DASHBOARD_PAGE_CARDS_SIZE_STORAGE_KEY = 'cards-size';

// filter and sorting in thingies view
export const DASHBOARD_PAGE_THINGIES_PROJECT_STORAGE_KEY = 'thingies-project';
export const DASHBOARD_PAGE_THINGIES_SIZE_STORAGE_KEY = 'thingies-size';


export const DASHBOARD_PAGE_CARDS_SORTING_STORAGE_KEY = 'cards_sorting';
export const DASHBOARD_THINGIE_TAB_STORAGE_KEY = '88810aac-7aeb-4d1d-823f-6515cebf8e04';
export const DASHBOARD_DEVICE_TAB_STORAGE_KEY = '88810aac-7aeb-4d1d-823f-6515cebf8e05';

// dashboard setting
// read and write as JSON format
export const DASHBOARD_PAGE_SETTINGS_STORAGE_KEY = 'dashboard-page-setting';
export const getDashboardSetting = (currentUserID: string, key: string): string | null => LocalStorageHelper.getItem(DASHBOARD_PAGE_SETTINGS_STORAGE_KEY, currentUserID, key);

export const updateDashboardSetting = (currentUserID: string, key: string, value: string) => {
  LocalStorageHelper.updateItem(DASHBOARD_PAGE_SETTINGS_STORAGE_KEY, currentUserID, key, value);
};

export const updateCurrentThingieTabId = (currentUserID: any, tabString: string) => {
  LocalStorageHelper.setItem(DASHBOARD_THINGIE_TAB_STORAGE_KEY, currentUserID, tabString);
};

export const updateCurrentDeviceTabId = (currentUserID: any, tabString: string) => {
  LocalStorageHelper.setItem(DASHBOARD_DEVICE_TAB_STORAGE_KEY, currentUserID, tabString);
};
