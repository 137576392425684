import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton-loader',
  template: `
    <div
      class="loader"
      [ngStyle]="getStyles()">

    </div>
  `,
  styleUrls: ['./skeleton-loader.component.scss']
})
export class SkeletonLoaderComponent {
  @Input()
  height: any;

  @Input()
  width: any;

  @Input()
  circle = false;

  getStyles() {
    const styles = {
      'width.px': this.width ? this.width : '',
      'height.px': this.height ? this.height : '',
      'border-radius': this.circle ? '50%' : ''
    };

    return styles;
  }
}
