import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonFab, IonFabButton, IonHeader, IonIcon, IonItem, IonItemGroup, IonLabel, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonSkeletonText, IonSpinner, IonTitle, IonToolbar } from '@ionic/angular/standalone';
import { MtxTooltipModule } from '@ng-matero/extensions/tooltip';
import { DisableAutocompleteDirective } from './directives/disable-autocomplete.directive';
import { KillInputWheelActionDirective } from './directives/kill-input-wheel-action/kill-input-wheel-action.directive';

// This needs to be exported because e.g. standalone IonIcon needs to be present in the bundle somehow.
// Noone needs to import it!
// Just needs to be here. Weird.
export const IonicImports: any[] = [
  // Ionic
  IonIcon,
  IonFab,
  IonFabButton,
  IonSpinner,
  IonLabel,
  IonSkeletonText,
  IonButton,
  IonHeader,
  IonSelect,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardContent,
  IonSelectOption,
  IonItem,
  IonItemGroup,
] as const;

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DisableAutocompleteDirective,
    KillInputWheelActionDirective
    //IonicImports,
    // IonFab,
    // IonSpinner,
    // IonLabel,
    // IonSkeletonText,
    // IonButton,
    // IonHeader,
    // IonSelect,
    // IonSegment,
    // IonSegmentButton,
    // IonTitle,
    // IonToolbar,
    // IonCard,
    // IonCardHeader,
    // IonCardSubtitle,
    // IonCardContent,
  ],
  exports: [
    MatToolbarModule,
    MatIconModule,
    MatBadgeModule,
    MatButtonModule,
    MatInputModule,
    MatMenuModule,
    MatListModule,
    MatGridListModule,
    MatTableModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatExpansionModule,
    MatTabsModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatChipsModule,
    MatCheckboxModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSnackBarModule,
    MatStepperModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatPaginatorModule,
    MatSortModule,

    // CDK
    OverlayModule,
    DragDropModule,
    PortalModule,

    // Matero
    MtxTooltipModule,

    DisableAutocompleteDirective,
    ClipboardModule,

    KillInputWheelActionDirective
  ]
})
export class MaterialImportsModule {
}
