import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';



type AllowedSizes = 's' | 'm' | 'l';
type SizeInputAllowedValues = AllowedSizes
| 'S' | 'M' | 'L'
| 'Small' | 'Medium' | 'Large'
| 'small' | 'medium' | 'large';

/**
 * Split button
 *
 * The main button's content has to be set via content-projection
 * as `selector=[buttonContent]`, e.g.:
 * ````
 * <span app-split-button>...</span>
 * ````
 *
 * The menu items are set via the default content-projection
 * slot, e.g.:
 * ````
 * <app-split-button>
 *  <button>...</button>
 *  <button>...</button>
 * </app-split-button>
 * ````
 */
@Component({
  selector: 'app-split-button',
  templateUrl: './split-button.component.html',
  styleUrls: ['./split-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SplitButtonComponent {

  /**
   * @private
   */
  @ViewChild(MatMenuTrigger)
  _trigger?: MatMenuTrigger;

  /**
   * The color of the button
   */
  @Input()
  color = 'primary';


  /**
   * The size of the button
   */
  @Input()
  set size(size: SizeInputAllowedValues) {
    const char = (size as string)[0]
      .toLowerCase();

    const allowed = ['s', 'm', 'l'];
    if (!['s', 'm', 'l'].includes(char)) {
      throw new Error(`
      Invalid size value. Allowed values are: ${allowed.join(', ')}
      `);
    }

    this._size = char as AllowedSizes;
  }


  @Input()
  set disabled(val: BooleanInput) {
    this._disabled = coerceBooleanProperty(val);
  }


  /**
   * If the button is disabled.
   *
   * Backing-field set via the setter {@link disabled}.
   *
   * @private
   */
  _disabled = false;


  /**
   * The size of the button set via setter {@link size}
   */
  private _size: AllowedSizes = 'm';


  constructor(private ref: ElementRef<HTMLElement>) { }


  /**
   * Provides the classes for dynamically setting the color
   * and size properties of the button
   *
   * @private
   */
  get _mainClasses() {
    return {
      [`colors-${this.color}`]: true,
      [`size-${this._size}`]: true,
      disabled: this._disabled
    };
  }

  /**
   * Press/Click event handler
   *
   * @param $event
   * @private
   */
  _onClick($event: MouseEvent) {
    $event.stopPropagation();
    this.ref.nativeElement.dispatchEvent(new MouseEvent('click', $event));
  }


  /**
   * Opens the menu
   */
  public open() {
    this._trigger?.openMenu();
  }
}
