import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { EMPTY, merge } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap } from 'rxjs/operators';
import { DeviceDetailDialogComponent } from 'src/app/components/dialogs/device-detail-dialog/device-detail-dialog.component';
import { AddDeviceDialogComponentService } from 'src/app/components/dialogs/devices/add-device-dialog/add-device-dialog.component';
import { TabFilterId as DeviceFilterId } from 'src/app/components/list-devices/list-devices.component';
import { TabFilterId as ThingieFilterId } from 'src/app/components/list-thingies/list-thingies.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import {
  DASHBOARD_DEVICE_TAB_STORAGE_KEY,
  DASHBOARD_PAGE_CARDS_SIZE_STORAGE_KEY,
  DASHBOARD_PAGE_GROUP_BY_STORAGE_KEY,
  DASHBOARD_PAGE_MODE_STORAGE_KEY,
  DASHBOARD_PAGE_THINGIES_SIZE_STORAGE_KEY,
  DASHBOARD_PAGE_VIEW_MODE_STORAGE_KEY,
  DASHBOARD_THINGIE_TAB_STORAGE_KEY,
  getDashboardSetting,
  updateDashboardSetting
} from 'src/app/utility/dashboard-helper';
import { SubSink } from 'subsink';

export interface IProjectInfo {
  projectName: string;
  projectId: string;
  projectNotEmpty: boolean;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  _menuBarTitle = 'Dashboard';
  _selectedThingieTab: ThingieFilterId = 'all';
  _selectedDeviceTab: DeviceFilterId = 'all';

  private _groupByExperimentChecked = true;
  get groupByExperimentChecked() {
    return this._groupByExperimentChecked;
  }

  set groupByExperimentChecked(checked: boolean) {
    this._groupByExperimentChecked = checked;
  }

  _mode = '';
  private _viewMode: 'thingies' | 'cards'  = 'cards';

  private deviceDialogRef?: MatDialogRef<DeviceDetailDialogComponent, void>;
  private subscriptions = new SubSink();

  pageSize = 16;
  currentPage = 1;
  refreshListSignal = false;
  private currentUserID!: string;

  get viewMode(): 'thingies' | 'cards' {
    return this._viewMode;
  }

  set viewMode(newViewMode: 'thingies' | 'cards') {
    this._viewMode = newViewMode;
  }

  constructor(
    private addDeviceDialog: AddDeviceDialogComponentService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private auth: AuthService
  ) {
    this.subscriptions.sink = router.events.subscribe((event) => {
      if (event instanceof NavigationStart && router.getCurrentNavigation()?.extras?.state?.refreshDashboard === true) {
        this.refreshListSignal = !this.refreshListSignal;
      }
    });
  }

  async ngOnInit() {
    this.currentUserID = await this.auth.getCurrentUserId();
    // check for the list or cards view
    const routeState = this.processRouteState(this.route.snapshot);
    this._mode = routeState.pageMode;
    this._viewMode = routeState.viewMode;
    this.pageSize = parseInt(routeState.pageSize, 10);
    this._selectedDeviceTab = routeState.deviceTab;
    this._selectedThingieTab = routeState.thingieTab;
    this.currentPage = parseInt(routeState.page, 10);

    // when navigating to this page, update the title
    // need to listen to router events because no route observables are
    // triggered due to the route reuse strategy.
    this.subscriptions.sink = this.router.events
      .pipe(filter(evt => evt instanceof NavigationEnd))
      .subscribe(() => {
        if (this.route.component === DashboardComponent) {
          this.setTitle();
        }
      });

    this.subscriptions.sink = merge(this.route.params, this.route.queryParams)
      .pipe(
        map(() => [this.route.snapshot.params, this.route.snapshot.queryParams]),
        startWith([this.route.snapshot.params, this.route.snapshot.queryParams]),
        debounceTime(10),
        switchMap(([pathParams, queryParams]) => {
          this.setPreselectedTab(queryParams.tab);

          if (this.router.url.startsWith('/dashboard')) {
            const routeState = this.processRouteState(this.route.snapshot);
            const mode = pathParams.id;
            if (mode !== null && mode !== '') {
              this._mode = routeState.pageMode;
              this._viewMode = routeState.viewMode;
              this._groupByExperimentChecked = routeState.isGroupingEnabled;
              this.currentPage = parseInt(routeState.page, 10);
              this.pageSize = parseInt(routeState.pageSize, 10);
              this._selectedDeviceTab = routeState.deviceTab;
              this._selectedThingieTab = routeState.thingieTab;
            } else {
              void this.router.navigate([`/dashboard/${routeState.modeUrl}`], { queryParams });
            }
          }

          if (this.router.url.startsWith('/devices')) {
            const id = pathParams.deviceid;
            if (id !== null) {
              if (this.deviceDialogRef !== undefined) {
                this.deviceDialogRef.close();
              }

              this.deviceDialogRef = this.dialog.open(DeviceDetailDialogComponent, { data: id });
              return this.deviceDialogRef.afterClosed().pipe(map(() => 'dialogClosed'));
            }
          }

          return EMPTY;
        })
      )
      .subscribe(reason => {
        if (reason === 'dialogClosed') {
          void this.router.navigateByUrl('/dashboard/devices');
        }
      });
  }

  processRouteState(snapshot: ActivatedRouteSnapshot) {
    const savedPageMode: string | null = getDashboardSetting(this.currentUserID, DASHBOARD_PAGE_MODE_STORAGE_KEY);
    const savedViewMode: string | null = getDashboardSetting(this.currentUserID, DASHBOARD_PAGE_VIEW_MODE_STORAGE_KEY);
    const savedIsGroupingEnabled: string | null = getDashboardSetting(this.currentUserID, DASHBOARD_PAGE_GROUP_BY_STORAGE_KEY);
    const savedPageSizeCards = getDashboardSetting(this.currentUserID, DASHBOARD_PAGE_CARDS_SIZE_STORAGE_KEY);
    const savedPageSizeThingieList = getDashboardSetting(this.currentUserID, DASHBOARD_PAGE_THINGIES_SIZE_STORAGE_KEY);
    const savedThingieTab = getDashboardSetting(this.currentUserID, DASHBOARD_THINGIE_TAB_STORAGE_KEY);
    const savedDeviceTab = getDashboardSetting(this.currentUserID, DASHBOARD_DEVICE_TAB_STORAGE_KEY);
    let pageMode = savedPageMode || 'thingies';
    let viewMode = savedViewMode || 'cards';
    let isGroupingEnabled = savedIsGroupingEnabled || 'true';
    const pageSizeCards = savedPageSizeCards || '8';
    const pageSizeThingieList = savedPageSizeThingieList || '10';
    let thingieTab = savedThingieTab || 'all';
    let deviceTab = savedDeviceTab || 'all';
    const pageModeFromUrl = snapshot.paramMap.get('id');
    const tabFromUrl = snapshot.queryParamMap.get('tab');
    const groupingFromUrl = snapshot.queryParamMap.get('grouped');
    let page = snapshot.queryParams.page;
    page = (page && page > 0) ? page : 1;

    let pageSize = '';

    if (pageModeFromUrl !== null) {
      if (pageModeFromUrl === 'devices') {
        pageMode = 'devices';
        if (tabFromUrl !== null) {
          deviceTab = tabFromUrl;
        }
      } else {
        pageMode = 'thingies';
        if (pageModeFromUrl === 'thingies') {
          viewMode = 'thingies';
        } else {
          viewMode = 'cards';
        }
        if (tabFromUrl !== null) {
          thingieTab = tabFromUrl;
        }
      }
    }
    if (viewMode === 'thingies') {
      pageSize = pageSizeThingieList;
    } else {
      pageSize = pageSizeCards;
    }
    if (groupingFromUrl !== null) {
      isGroupingEnabled = groupingFromUrl;
    }
    if (pageMode !== savedPageMode) {
      updateDashboardSetting(this.currentUserID, DASHBOARD_PAGE_MODE_STORAGE_KEY, pageMode);
    }
    if (viewMode !== savedViewMode) {
      updateDashboardSetting(this.currentUserID, DASHBOARD_PAGE_VIEW_MODE_STORAGE_KEY, viewMode);
    }
    if (isGroupingEnabled !== savedIsGroupingEnabled) {
      updateDashboardSetting(this.currentUserID, DASHBOARD_PAGE_GROUP_BY_STORAGE_KEY, isGroupingEnabled);
    }
    if (thingieTab !== savedThingieTab) {
      updateDashboardSetting(this.currentUserID, DASHBOARD_THINGIE_TAB_STORAGE_KEY, thingieTab);
    }
    if (deviceTab !== savedDeviceTab) {
      updateDashboardSetting(this.currentUserID, DASHBOARD_DEVICE_TAB_STORAGE_KEY, deviceTab);
    }

    const routeState = {
      pageMode: pageMode as 'thingies' | 'devices',
      viewMode: viewMode as 'thingies' | 'cards',
      modeUrl: pageMode === 'devices' ? 'devices' : viewMode,
      isGroupingEnabled: isGroupingEnabled === 'true',
      page,
      pageSize,
      thingieTab: thingieTab as ThingieFilterId,
      deviceTab: deviceTab as DeviceFilterId,
    };

    return routeState;
  }

  ngAfterViewInit(): void {
    this.setTitle();
  }

  getUrlForMode(mode: string) {
    if (mode === 'devices') {
      return '/dashboard/devices';
    } else {
      const dashboardSavedViewMode = getDashboardSetting(this.currentUserID, DASHBOARD_PAGE_VIEW_MODE_STORAGE_KEY) || 'cards';
      return `/dashboard/${dashboardSavedViewMode}`;
    }
  }

  changeMode(event: { value: string }) {
    void this.router.navigate([this.getUrlForMode(event.value)], { queryParams: { ...this.route.snapshot.queryParams, tab: undefined } });
  }

  /**
   * Sets titles for window and the menu bar
   */
  public setTitle() {
    this.titleService.setTitle('Dashboard / DOTS');
  }

  getBreadcrumbNameByMode(name: string) {
    switch (name) {
      case 'thingies':
        return 'Object List';
      case 'cards':
        return 'Cards Overview';
      case 'devices':
        return 'Device List';
      default:
        return '';
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private setPreselectedTab(val: ThingieFilterId | DeviceFilterId) {
    if (val === undefined) {
      val = 'all';
    }

    if (this.router.url.includes('devices')) {
      this._selectedDeviceTab = val as DeviceFilterId;
    } else {
      this._selectedThingieTab = val as ThingieFilterId;
    }
  }

  _openAddDeviceDialog() {
    this.addDeviceDialog.open();
  }
}


