

/**
 * Implementation of Boyer–Moore–Horspool algorithm. Searches for `needle` in `haystack`.
 * Returns the indices of `count` first matches, or all matches if `count` is not set.
 * See https://en.wikipedia.org/wiki/Boyer%E2%80%93Moore%E2%80%93Horspool_algorithm
 *
 * @param needle search term
 * @param haystack buffer to search through
 * @param count number of results to return. If unset, returns all matches.
 * @returns indices in `haystack` of occurrences of `needle`
 */
export function searchBytes(needle: Uint8Array, haystack: Uint8Array, count?: number | undefined) {

  /**
   * Preprocess the search string to get a lookup table of safe jumping distances
   * while traversing the haystack.
   *
   * @param needle search string
   * @returns lookup table for safe jumping distances
   */
  const preprocess = (needle: Uint8Array) => {
    const arr = Array<number>(256);
    for (let i = 0; i < 256; i++) {
      arr[i] = needle.length;
    }
    for (let i = 0; i < needle.length - 1; i++) {
      arr[needle[i]] = needle.length - i - 1;
    }
    return arr;
  };

  /**
   * Compare two strings in buffers at an offset, starting from the back
   *
   * @param aBuf left-hand side buffer of comparison
   * @param aIndex left-hand side start index in buffer of string to compare
   * @param bBuf right-hand side buffer of comparison
   * @param bIndex right-hand side start index in buffer of string to compare
   * @param len length of string to compare
   * @returns whether aBuf.slice(aIndex, aIndex+len) === bBuf.slice(bIndex, bIndex+len)
   */
  const same = (aBuf: Uint8Array, aIndex: number, bBuf: Uint8Array, bIndex: number, len: number) => {
    let i = len - 1;
    while (aBuf[aIndex + i] === bBuf[bIndex + i]) {
      if (i === 0) {
        return true;
      }
      i -= 1;
    }
    return false;
  };

  /**
   * Boyer–Moore–Horspool algorithm to find occurrences of `needle` in `haystack`.
   * Get a jump lookup table based on the `needle`.
   * Match the pattern starting from the back. If a mismatch has been found, advance the
   * skip pointer by lookup[mismatching_character].
   * Abort early if enough results have been found.
   *
   * @param needle search term
   * @param haystack string to search in
   * @returns indices in `haystack` of occurrences of `needle`
   */
  const search = (needle: Uint8Array, haystack: Uint8Array) => {
    const lookup = preprocess(needle);
    let skip = 0;
    const result = [];
    while (haystack.length - skip >= needle.length) {
      if (same(haystack, skip, needle, 0, needle.length)) {
        result.push(skip);
        skip += needle.length;
      }
      if (count !== undefined && result.length >= count) {
        return result;
      }
      skip += lookup[haystack[skip + needle.length - 1]];
    }
    return result;
  };

  return search(needle, haystack);
}
