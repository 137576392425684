import * as jsonpatch from 'fast-json-patch';
import { ICloneable } from '../model/cloneable';
import { deepFreeze } from './deep-freeze';

function doMessageChannelClone(refToClone: any) {
  return new Promise(resolve => {
    const { port1, port2 } = new MessageChannel();
    port2.onmessage = ev => {
      resolve(ev.data); port2.onmessage = null;
    };
    port1.postMessage(refToClone);
  });
}

/**
 * Utility function for creating deep copies
 *
 * Uses the structural cloning algorithm (@see https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Structured_clone_algorithm).
 *
 * @throws {Error} If structural cloning is not possible
 */
export async function deepCopy<T>(data: T): Promise<T> {
  try {
    if (isCloneable(data)) {
      return data.clone();
    }

    if (window && window.MessageChannel) {
      const copy = await doMessageChannelClone(data) as T;
      return copy;
    } else {
      throw new Error('No window or MessageChanngel available.');
    }
  } catch (e: unknown) {
    console.warn(`Structural cloning not available/possible.
      Falling back to synchronous implementation. Error: ${e instanceof Error ? e.toString() : 'unknown'}`);

    return deepCopySync(data);
  }
}

/**
 * Same as @function deepCopy but makes
 * the resulting copy immutable
 *
 * @param data The original
 * @returns The immutable copy
 */
export async function deepCopyFrozen<T>(data: T): Promise<T> {
  return deepFreeze(await deepCopy(data));
}

/**
 * Creates a deep copy
 *
 * @param data The original
 * @returns The copy
 */
export function deepCopySync<T>(data: T): T {
  if (isCloneable(data)) {
    return data.clone();
  }

  return jsonpatch.deepClone(data);
}

/**
 * Same as @function deepCopySync but makes
 * the resulting copy immutable
 *
 * @param data The original
 * @returns The immutable copy
 */
export function deepCopyFrozenSync<T>(data: T): T {
  return deepFreeze(deepCopySync(data));
}

function isCloneable(obj: any): obj is ICloneable {
  return obj !== undefined && obj.clone !== undefined;
}
