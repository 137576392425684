import { Attribute, Directive, HostBinding } from '@angular/core';

@Directive({

  selector: 'input, [matInput], [appDisableAutocomplete]',
  standalone: true
})
export class DisableAutocompleteDirective {
  @HostBinding('attr.autocomplete')
  autocomplete: unknown;

  constructor(@Attribute('autocomplete') autocomplete: unknown) {
    this.autocomplete = autocomplete ?? 'off';
  }
}
