import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IDialogResult as DataExportDTO } from 'src/app/components/dialogs/object-export-dialog/object-export-dialog.component';
import { AnnotationSeries } from 'src/app/model/annotation-series';
import { IDataSeries } from 'src/app/model/data-series';
import { IAnnotation, IAnnotationAttachment } from 'src/app/model/thingie/annotation';
import { IThingie, IThingieIdName } from 'src/app/model/thingie/thingie';
import { ThingieServiceImpl } from './implementation/thingie-service.service';

export interface ISetTargetStateEntry {
  thingieId: string;
  process: number;
  task: number;
  targetState: string;
}

export interface IThingieMostRecentPagination {
  result: IThingie[];
  pageSize: number;
  collectionSize: number;
  totalSize: number;
  currentPage: number;
}

export interface IAttachmentLocation {

  /**
   * ID of thingie
   */
  thingieId: string;

  /**
   * Index of process which contains the annotation
   */
  processIdx: number;

  /**
   * Index of annotation which contains the attachment
   */
  annotationIdx: number;

  /**
   * Index of attachment to delete
   */
  attachmentIdx: number;
}

export interface ICreateAnnotationsResultLocation {
  thingieId: string;
  processIndex: number;
  annotationIndex: number;
}

export interface ICreateAnnotationsResult {
  locations: ICreateAnnotationsResultLocation[];
}


@Injectable({
  providedIn: 'root',
  useClass: ThingieServiceImpl
})
export abstract class ThingieService {
  abstract setTaskTargetState(thingieId: string, processIdx: number, taskIdx: number, state: string): Promise<void>;

  /**
   * Out-of-band-refresh the internal thingie list.
   *
   * Pushes the thingie updates to subscribers.
   *
   * @return Observable which completes once the refresh is done.
   */
  abstract refresh(): Observable<void>;

  abstract getThingieList(): Observable<IThingie[]>;

  abstract getThingiesByIds(ids: string[]): Observable<IThingie[]>;

  abstract getThingieNameInfoRequest(ids?: string[]): Observable<IThingieIdName[]>;

  abstract getThingieNewState(): Observable<{ state: string; thingieId: string }>;

  abstract getThingieObservables(): Observable<Observable<IThingie>[]>;

  abstract getRecentThingieList$$(page: number, pageSize: number, filter: object): Observable<Observable<IThingie>[]>;

  abstract getRecentThingieList$(): Observable<IThingie[]>;

  /**
   * Acquire thingie/object
   *
   * @param thingieId
   * @param writableCopy If `true`, returns a writeable copy.
   *  If `false` or `undefined`, returns a frozen instance, which is faster.
   * @returns Observable which returns the requested thingie
   *  on each subscription
   */
  abstract getThingieById(thingieId: string, writableCopy?: boolean): Observable<IThingie>;

  abstract getRelationsOfThingieById(thingieId: string): Observable<IThingie[]>;

  abstract getAvailableDataSeriesFromThingie(thingie: IThingie, processIdx?: number, taskIdx?: number): IDataSeries[];

  abstract getAnnotationSeriesFromThingie(thingie: IThingie): AnnotationSeries[];

  abstract createThingie(thingie: IThingie): Promise<IThingie>;

  /**
   * Update thingie
   *
   * @param thingie The new thingie state. Has to have a valid ID.
   * @throws Error if the thingie has no ID.
   */
  abstract updateThingie(thingie: IThingie): Promise<IThingie>;

  abstract executeProcessAction(action: string, thingieId: string, processIdx: number): Promise<IThingie>;

  abstract executeTaskAction(action: string, thingieId: string, processIdx: number, taskIdx: number): Promise<any>;

  abstract exportThingieData(
    data: DataExportDTO,
    abortSignal?: AbortSignal
  ): Promise<{ data: Blob | null; type: 'raw' | 'zip'; fileName: string | null }>;

  /**
   * Create and add all provided annotations to all thingies
   * provided as Thingie-IDs
   *
   * @param thingies List of Thingie-IDs
   * @param annotations The annotations to create
   */
  abstract createAnnotations(
    opt: { thingies: string[]; annotations: IAnnotation[] }
  ): Promise<ICreateAnnotationsResult>;

  abstract editAnnotation(thingieId: string, annotationId: number, annotation: IAnnotation): Promise<IThingie>;

  abstract uploadAttachment(thingieId: string, annotationId: number, file: File[]): Promise<IAnnotationAttachment[]>;

  /**
   * Marks the attachments identified by the parameter as deleted.
   *
   * @param attachmentLocations The locations of attachment to delete
   * @returns Promise which resolves once the attachments are deleted, rejects otherwise
   */
  abstract removeAttachments(attachmentLocations: IAttachmentLocation[]): Promise<void>;

  /**
   * Creates a zip file containing all attachments identified by the parameter.
   *
   * @param hashes of the attachments to include in the ZIP file
   * @returns the URI linking to the file
   */
  abstract createAttachmentZipFile(attachmentHashes: string[]): Promise<string>;

  /**
   * Sets the deleted flag of the attachments identified by the parameter to false.
   *
   * @param attachmentLocations The locations of attachments to "undelete"
   */
  abstract undeleteAttachments(attachmentLocations: IAttachmentLocation[]): Promise<void>;

  abstract downloadAnnotationAttachment(annotationAttachment: IAnnotationAttachment): Promise<void>;

  abstract deleteAnnotations(thingieId: string, annotationId: number): Promise<IThingie>;

  abstract archiveThingie(thingieId: string): Promise<void>;

  abstract unarchiveThingie(thingieId: string): Promise<void>;

  abstract imagePreview(): Promise<string>;

  abstract getVersion(): Observable<string>;

  abstract getTags(): Observable<string[]>;

  abstract getOfflineDataUnits(): Observable<string[]>;

  abstract importThingie(file: File[], replacementProjectId: string | undefined, replacementTeamId: string | undefined): Promise<any>;

  abstract batchCreateThingies(thingies: IThingie[]): Promise<IThingie[]>;

  /**
   * Update multiple thingies in one request
   *
   * The request will fail if any of the thingies fails to update.
   * In this case, no thingie will be updated.
   *
   * @param thingies
   */
  abstract batchUpdateThingies(thingies: IThingie[]): Promise<IThingie[]>;

  /**
   * Check for taken experiment names
   *
   * And ID can be added to each entry, which will be used
   * to ignore elements with the matching ID.
   *
   * @param entries List of name entries to check
   * @returns List of taken names from the list provided
   */
  abstract checkForTakenNames(entries: { id?: string; name: string }[]): Observable<string[]>;

  /**
   * Check for taken thingie names
   *
   * And ID can be added to each entry, which will be used
   * to exlucde/skip thingies with the matching IDs from being checked against.
   * E.g. when updating a thingie, the ID of the thingie to update can be provided
   * to exclude it's name from the check.
   *
   * @param entries List of name entries to check
   * @returns List of taken names from the list provided
   */
  abstract checkForTakenThingieNames(entries: { id?: string; name: string }[]): Observable<string[]>;

  /**
   * Set task target states for multiple thingie-task
   * combinations in one request
   *
   * @param dto The context of the thingies/tasks and the
   *   states to set for each
   */
  abstract batchSetTargetStates(dto: ISetTargetStateEntry[]): Observable<void>;


  /**
   * fetch the data in paginated format
   *
   * @returns an object which conaint list of thiniges, page size, current page,
   * collection size and total number of thingies
   */
  abstract getMostRecentPagination(): IThingieMostRecentPagination;
}
