import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private keycloak: KeycloakService,
    private userService: UserService
  ) { }

  /**
   * Determine if the current user is an admin
   *
   * @returns true if the current user is an admin, false otherwise
   */
  async isAdmin(): Promise<boolean> {
    const profile = await this.keycloak.loadUserProfile();
    if (!profile.id) {
      throw new Error('User ID is missing from user profil');
    }

    const user = await this.userService.getUserById({ userId: profile.id });

    return user.admin;
  }

  async getCurrentUserId() {
    const id = (await this.keycloak.loadUserProfile()).id;
    if (id === undefined) {
      throw new Error('Failed to acquire current user ID from Keycloak profile');
    }

    return id;
  }

  /**
   * Determine if the current user is a team leader
   */
  async isTeamLeader(): Promise<boolean> {
    const userId = await this.getCurrentUserId();

    const user = await this.userService.getUserById({ userId });

    for (const team of Object.keys(user.teams)) {
      if (user.teams[team] === 'LEADER') {
        return true;
      }
    }

    return false;
  }
}
