import { IPojoDevice } from 'src/app/model/device/IPojoDevice';

type Result = IPojoDevice['configuration']['TcpConnection'] | IPojoDevice['info']['KuhnerShakerLTXSetpoints' | 'KuhnerShakerLTXParameterReport' | 'KuhnerShakerLTXStatus'];

/**
 * Extracts the configuration or info item of a Kuhner shaker from a device object.
 *
 * @param shaker The Kuhner shaker device object.
 * @param type The type of configuration to extract.
 * @returns The configuration or info item.
 * @throws If the device is not a Kuhner shaker or if the configuration item could not be found.
 */
export function extractConfigurationFromKuhnerShaker(
  shaker: IPojoDevice,
  type: 'TcpConnection' | 'KuhnerShakerLTXSetpoints' | 'KuhnerShakerLTXParameterReport' | 'KuhnerShakerLTXStatus'
): Result {
  if (shaker.devType !== 'KuhnerShaker') {
    throw new Error('Device is not a Kuhner shaker');
  }

  if (shaker.info[type] !== undefined) {
    return shaker.info[type];
  }

  let result: Result | undefined;

  for (const config of Object.values(shaker.configuration)) {
    if (config.type === type) {
      result = config;
      break;
    }
  }

  if (result === undefined) {
    throw new Error(`Could not find configuration of type ${type}`);
  }

  return result;
}
